import Swal from 'sweetalert2';
import { SiteTypesT } from './types';

export const SITES: Array<{
  value: SiteTypesT,
  label: string,
}>  = [
  {
    value: 'I14',
    label: 'Ascension Saint Joseph - Elgin',
  },
  {
    value: 'I15',
    label: 'Ascension Mercy',
  },
  {
    value: 'I16',
    label: 'Ascension Saint Francis',
  },
  {
    value: 'I17',
    label: 'Ascension Resurrection',
  },
  {
    value: 'I18',
    label: 'Ascension Saint Joseph - Chicago',
  },
  {
    value: 'I19',
    label: 'Ascension Saint Mary -  Chicago',
  },
  {
    value: 'I22',
    label: 'Ascension Saint Joseph -  Joliet',
  },
  {
    value: 'I23',
    label: 'Ascension Saint Mary -  Kankakee',
  }
];


export const SITES_WITH_SITE_CODE: Array<{
  value: SiteTypesT,
  label: string,
}> = SITES.map((site) => ({
  value: site.value,
  label: `${site.value} - ${site.label}`,
}));

export const getSiteNameFromSiteCode = (siteCode: SiteTypesT): string => {
  const site = SITES.find((site) => site.value === siteCode);
  if (site == null) {
    Swal.fire({
      icon: 'error',
      title: 'Invalid Site Code',
      text: `${siteCode} is an invalid site code`,
    })
  }
  return site ? site.label : 'Ascension Health';
};
