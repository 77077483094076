import _ from 'lodash';

export type SheetTypeT = 'EPIC-Microstrategy' | 'EPIC-Referrals' | 'Meditech-Microstrategy' | 'Meditech-Referrals';

const SHEET_TYPES: SheetTypeT[] = ['EPIC-Microstrategy', 'EPIC-Referrals', 'Meditech-Microstrategy', 'Meditech-Referrals'];

const EPIC_PAYOR_COLUMNS = [
  'Payor',
  'Primary Mem ID',
  'Secondary Mem ID',
  'Secondary Payor',
  'Medications'
];

const EXPECTED_COLUMNS: Record<SheetTypeT, string[]> = {
  'EPIC-Microstrategy': ["ED", "Sex", "Gender", "Is Pregnant?", "Race", "Ethnicity", "Language", "Pref Language", "Arr Date", "Arr Time", "MRN", "Hospital Account ID", "First Name", "Last Name", "Date of Birth", "Age (Years)", "Pt. E-mail Address", "Phone", "Address", "CC", "Primary ED Dx", "ED Dx", "PCP w/ Phone", "ED Vis Last 90 Days", "Freq", "First Attending", "Resident/Med Student", "Fin Class", "ED Disposition", "Hosp Disch Dest", "Last Attending", "Emerg Contact", "Emerg Contact Ph", ...EPIC_PAYOR_COLUMNS],
  'EPIC-Referrals': ["ED", "Sex", "Gender", "Is Pregnant?", "Race", "Ethnicity", "Language", "MRN", "Hospital Account ID", "First Name", "Last Name", "Date of Birth", "Pt. E-mail Address", "Phone", "Address", "Fin Class", "Coverage", "Coverage", "Arr Date", "Arr Time", "Ordering Provider", "Order Date", "Question response", "CC", "Primary ED Dx", "ED Dx", "ED Disposition", "Hosp Disch Dest", "PCP w/ Phone", "ED Vis Last 90 Days", "Freq", "Emerg Contact", "Emerg Contact Ph", ...EPIC_PAYOR_COLUMNS],


  // meditech microstaregy --> deleted  "Ethnicity", "Gender", ""
  'Meditech-Microstrategy': ["Site_Name", "Patient_First_Name", "Patient_Last_Name", "Language", "Address1", "Address2", "City", "State", "Zip", "Primary_Phone", "Alternate_Phone1", "Alternate_Phone2", "Date_of_Birth", "Email", "MRN", "AccountNumber", "Sex","Race","Pregnant", "PCP", "PCP_Phone", "ED_Arrival", "Visits_6_Months", "Discharge_DTS", "ED_Disposition", "CC",  "Rx_Prescribed", "Secondary_Contact", "Secondary_Contact_Phone", "Instructions", "Referrals", "Prescriptions", "Primary_Insurance_Payor_Name", "Primary_InsuranceID", "Primary_Policy_Number"],
  // mediteech referrals --> deleted Gender, Ethnicity, Referral_Comments, Discharge_Disposition, Adm_Unit
  'Meditech-Referrals': ["Site_Name", "Site_Code", "Arr_Date", "Arr_Time", "Visits_6_Months", "Patient_First_Name", "Patient_Last_Name", "Primary_Phone", "Alternate_Phone1", "Alternate_Phone2", "Email", "Sex", "Race","Pregnant", "Language", "Date_of_Birth", "Address1", "Address2", "City", "State", "Zip", "MRN", "AccountNumber", "Referring_Provider", "Message_Date", "Message_Time", "Reason_For_Referral", "CC", "Diagnoses", "ED_Disposition",  "PCP", "PCP_Phone", "Rx_Prescribed", "Secondary_Contact", "Secondary_Contact_Phone", "Instructions", "Referrals", "Prescriptions", "Primary_Insurance_Payor_Name", "Primary_InsuranceID", "Primary_Policy_Number"]
};

const getMissingColumnsForType = (rows: Object[], type: SheetTypeT): {
  missingColumns: string[],
  additionalColumns: string[],
} => {
  const expectedColumns = EXPECTED_COLUMNS[type];
  const allColumns = _.uniq(_.flatten(rows.map(row => Object.keys(row))));
  const missingColumns =  _.difference(expectedColumns, allColumns);
  const additionalColumns = _.difference(allColumns, expectedColumns);
  return {
    missingColumns,
    additionalColumns,
  };
}

export const getMissingColumns = (
  rows: Object[],
): {
  detectedType: SheetTypeT,
  missingColumns: string[] | null,
} => {
  // find type with fewest missing columns
  const targetType = _.minBy(SHEET_TYPES, (type: SheetTypeT) => {
    const { missingColumns, additionalColumns } = getMissingColumnsForType(rows, type);
    return missingColumns.length + additionalColumns.length;
  });
  if (targetType == null) {
    throw new Error('Invalid CSV');
  }
  return {
    detectedType: targetType,
    missingColumns: getMissingColumnsForType(rows, targetType).missingColumns.length > 0
      ? getMissingColumnsForType(rows, targetType).missingColumns
      : null,
  }
}
