import _ from 'lodash';
import React, { useMemo } from 'react';
import CardItem from '../utils/CardItem';
import { NavigatorSchemaT } from "../navAssignment/navOptions";
import NavData from './NavData';
import { type MergedDataT } from "../mergedColumns/mergeData";
import './index.scss';


const NavAssignment = ({
  setNavAssignment,
  navAssignments,
  mergedData,
}: {
  navAssignments: Array<NavigatorSchemaT>,
  setNavAssignment: (navAssignments: Array<NavigatorSchemaT>) => void,
  mergedData: MergedDataT[],
}) => {
  const navsAssigned = useMemo(() => (
    _.groupBy(
      mergedData,
      'data.Navigator Name',
    )),
    [mergedData],
  );
  return (
    <CardItem className="NavAssignment bg-white d-block mx-5 mt-5" title="Navigator Assignments">
      <div className="d-flex flex-wrap">
        {navAssignments.map((item, index) => (
          <NavData
            key={item.navigatorName}
            patientsAssigned={navsAssigned[item.navigatorName] || []}
            navData={item}
            index={index}
            setNavData={(navData) => {
              setNavAssignment(navAssignments.map((navAssignment) => (
                navAssignment.navigatorName === item.navigatorName
                  ? navData
                  : navAssignment
              )));
            }}
          />
        ))}
      </div>
    </CardItem>
  );
};

export default NavAssignment;
