import _ from "lodash";
import Swal from "sweetalert2";
import moment from "moment";
import { PriorityLevelT, SymptoData } from "../types";
import { DEFAULT_PROVIDER, getPayorCategory, getSex, getRxPrescribed } from "../csvParsing/genericUtils";
import { getSiteNameFromSiteCode } from '../siteData';

export type UnassignedMergedDataT = {
  data: Omit<SymptoData, 'Identification' | 'No Service Introduction' | 'Navigator Name' | 'Secondary Navigator Name' | 'Intro Message'>,
  warnings: null | string[],
  isProviderReferral: boolean,
};

const filterEmptyValues = <T>(rowValues: T[]): T[] => (
  _.uniq(rowValues.filter((row) => row != null && String(row).trim().length > 0))
);


export const mergeDuplicates = (
  data: Omit<SymptoData, 'Intro Message' | 'Full Site Name' | 'Payor Category' | 'Secondary Navigator Name'>[],
): UnassignedMergedDataT[] => {
  const allPatients = _.sortBy(
    data,
    // Sort by Payor Category as Medicaid/Medicare.
    (row) => {
      return row.Identification === 'Provider Referral' ? 0 : 1;
    }
  )
  // group by mrn / encounter number combo
  const groupedData = _.groupBy(allPatients, (a) => (`${a.MRN}${a['Encounter Number']}`))
  const mergedData: UnassignedMergedDataT[] = _.values(groupedData).map((rowsForEncounter): UnassignedMergedDataT => {
    const errorData: {
      errors: string[],
    } = {
      errors: [],
    };
    const mainEncounter = _.head(rowsForEncounter);
    if (mainEncounter === undefined) {
      Swal.fire({
        title: 'Fatal Error',
        text: 'This should never happen. Refresh page nad try again',
        icon: 'error',
      })
      throw new Error('This should never happen');
    }
    // error if we have multiple different first names for the same mrn / encounter number combo
    const firstNames = filterEmptyValues(rowsForEncounter.map((a) => a['First Name']));
    const targetFirstName = _.head(firstNames);
    if (targetFirstName == null || firstNames.length > 1) {
      errorData.errors.push(`First name ${targetFirstName} chosen from ${firstNames.join(', ')}`);
    }

    // error if we have multiple different last names for the same mrn / encounter number combo
    const lastNames = filterEmptyValues(rowsForEncounter.map((a) => a['Last Name']));
    const targetLastName = _.head(lastNames);
    if (targetLastName == null || lastNames.length > 1) {
      errorData.errors.push(`Last name ${targetLastName} chosen from ${lastNames.join(', ')}`);
    }


    // error if we have multiple different phone numbers for the same mrn / encounter number combo
    const phoneNumbers = filterEmptyValues(rowsForEncounter.map((a) => a['Phone Number']));
    const targetPhoneNumber = _.head(phoneNumbers);
    if (targetPhoneNumber == null || phoneNumbers.length > 1) {
      errorData.errors.push(`Phone number ${targetPhoneNumber} chosen from ${phoneNumbers.join(', ')}`);
    }

    const altPhoneNumbers = filterEmptyValues(rowsForEncounter.map((a) => a['Alt Phone #']));
    const targetAltPhoneNumber = _.head(altPhoneNumbers);
    if (targetAltPhoneNumber == null || altPhoneNumbers.length > 1) {
      errorData.errors.push(`Alt Phone number ${targetAltPhoneNumber} chosen from ${altPhoneNumbers.join(', ')}`);
    }

    const dobs = filterEmptyValues(rowsForEncounter.map((a) => a['Date of Birth']));
    const targetDOB = _.head(dobs);
    if (targetDOB == null || dobs.length > 1) {
      errorData.errors.push(`DOB ${targetDOB} chosen from ${dobs.join(', ')}`);
      alert(`This is invalid. Please refresh your page and look at your data. DOB ${targetDOB} chosen from ${dobs.join(', ')} for MRN ${mainEncounter.MRN} and Encounter Number ${mainEncounter['Encounter Number']}`);
      throw new Error('This is invalid. Please refresh your page and look at your data');
    }

    // make sure that only one site selected, otherwise choose first one
    const sites = filterEmptyValues(rowsForEncounter.map((a) => a.Sites));
    const targetSite = _.head(sites);
    if (sites.length > 1) {
      errorData.errors.push(`Site ${targetSite} chosen from ${sites.join(', ')}`);
    }
    if (targetSite == null) {
      throw new Error('Site should never be null');
    }

    // if there are multiple ed visit dates for the same mrn / encounter number combo, then choose the most recent one
    const edVisitDates = filterEmptyValues(rowsForEncounter.map((a) => a['ED Visit/Referral Date']));
    const mostRecentEDDate = moment.max(edVisitDates.map((a) => moment(a, 'MM/DD/YYYY'))).format('MM/DD/YYYY');
    if (mostRecentEDDate == null || edVisitDates.length > 1) {
      errorData.errors.push(`ED Visit Date ${mostRecentEDDate} chosen from ${edVisitDates.join(', ')}`);
    }

    // merge all Provider / On-Site Coordinator Name  except for Digital Communications Coordinator
    // unlless there is only one, then we want to use that one
    const providerNames = filterEmptyValues(rowsForEncounter.map((a) => a['Provider / On-Site Coordinator Name']));
    const formattedProviderNames = providerNames.filter((a) => a !== DEFAULT_PROVIDER).length > 0
      ? providerNames.filter((a) => a !== DEFAULT_PROVIDER)
      : providerNames;

    const primaryLanguages = filterEmptyValues(rowsForEncounter.map((a) => a['Patient\'s Primary Language']));
    // if we have english along with other languages, we want to remove english
    const formattedPrimaryLanguages = primaryLanguages.filter((a) => a !== 'English').length > 0
      ? primaryLanguages.filter((a) => a !== 'English')
      : primaryLanguages;

    const spanishSpeaking = filterEmptyValues(rowsForEncounter.map((a) => a['Spanish Speaking']));
    const anySpanishSpeaking = spanishSpeaking.includes('TRUE');

    const navReasons = filterEmptyValues(
      _.uniq(_.flatten(rowsForEncounter.map((a) => a['Reason for Navigation'].split(','))))
    );

    const visitsInSixMonths = filterEmptyValues(rowsForEncounter.map((a) => a['Visits within 6 mo\'s']));
    const maxVisitsInSixMonths = _.max(visitsInSixMonths.map((a) => Number(a)));

    const allPriorityLevels = filterEmptyValues(rowsForEncounter.map((a) => a['Priority Level']));
    const updatedPriority = ((): PriorityLevelT => {
      // if we have a high priority, we want to return high
      if (allPriorityLevels.includes('3) High')) {
        return '3) High';
      }
      if (allPriorityLevels.includes('2) Medium')) {
        return '2) Medium';
      }
      if (allPriorityLevels.includes('1) Low')) {
        return '1) Low';
      }
      return '';
    })();

    const payerTypes = filterEmptyValues(rowsForEncounter.map((a) => a['Payer Type']));
    const targetPayerType = _.head(payerTypes);
    if (targetPayerType == null || payerTypes.length > 1) {
      errorData.errors.push(`Payer Type ${targetPayerType} chosen from ${payerTypes.join(', ')}`);
    }
    const encounterNotes = _.uniq(rowsForEncounter.map((a) => a['Encounter Details/Comments']))
      .join('\t');

    const fetchValueFromMultipleRows = (key: 'Sex' | 'Gender' | 'Race' | 'Address' | 'Ethnicity' | 'InsuranceID' | 'Rx Prescribed' | 'PCP Medical Group', showErrorOnUndefined: boolean): string => {
      const allValues = _.uniq((filterEmptyValues(rowsForEncounter.map((a) => a[key]))).map(r => r.trim()));
      const targetValue = _.head(allValues);
      if ((targetValue == null && showErrorOnUndefined) || allValues.length > 1) {
        errorData.errors.push(`${key} ${targetValue} chosen from ${allValues.join(', ')}`);
      }
      return targetValue || '';
    };

    const pregnantAllValues = filterEmptyValues(rowsForEncounter.map((a) => a['Is Pregnant?']));
    const targetPregnantValue = pregnantAllValues.includes('Yes')
      ? 'Yes'
      : _.head(pregnantAllValues);
    if (targetPregnantValue == null || pregnantAllValues.length > 1) {
      errorData.errors.push(`Is Pregnant ${targetPregnantValue} chosen from ${pregnantAllValues.join(', ')}`);
    }
    const baseDemographics = {
      'First Name': targetFirstName || '',
      'Last Name': targetLastName || '',
      'Phone Number': targetPhoneNumber || '',
      'Alt Phone #': targetAltPhoneNumber || '',
      'Email Address': filterEmptyValues(rowsForEncounter.map((a) => a['Email Address'])).join(', '),
      'Encounter Number': mainEncounter['Encounter Number'],
      MRN: mainEncounter.MRN,
      Sites: targetSite,
      'Full Site Name': getSiteNameFromSiteCode(targetSite),
      'Date of Birth': targetDOB || '',
      'ED Visit/Referral Date': mostRecentEDDate,
      'Provider / On-Site Coordinator Name': formattedProviderNames.join(' & '),

      'Patient\'s Primary Language': formattedPrimaryLanguages.join(' & '),
      'Spanish Speaking': (anySpanishSpeaking ? 'TRUE' : 'FALSE' as 'TRUE' | 'FALSE'),
      'Reason for Navigation': navReasons.join(','),
      'Visits within 6 mo\'s': String(maxVisitsInSixMonths),
      'Priority Level': updatedPriority,
      Sex: getSex(fetchValueFromMultipleRows('Sex', true)),
      Gender: fetchValueFromMultipleRows('Gender', false),
      'Is Pregnant?': targetPregnantValue || 'Unknown',
      'PCP Medical Group': fetchValueFromMultipleRows('PCP Medical Group', false),
      Race: fetchValueFromMultipleRows('Race', true),
      Address: fetchValueFromMultipleRows('Address', true),
      'Encounter Details/Comments': encounterNotes,
      'Chief Complaint': filterEmptyValues(rowsForEncounter.map((a) => a['Chief Complaint'])).join(', '),
      'Discharge Diagnosis': filterEmptyValues(rowsForEncounter.map((a) => a['Discharge Diagnosis'])).join(', '),
      'PCP Provider Name': filterEmptyValues(rowsForEncounter.map((a) => a['PCP Provider Name'])).join(', '),
      'PCP Phone': filterEmptyValues(rowsForEncounter.map((a) => a['PCP Phone'])).join(', '),
      'Provider NPI': filterEmptyValues(rowsForEncounter.map((a) => a['Provider NPI'])).join(', '),
      'Provider Specialty': filterEmptyValues(rowsForEncounter.map((a) => a['Provider Specialty'])).join(', '),
      'Clinic Name': filterEmptyValues(rowsForEncounter.map((a) => a['Clinic Name'])).join(', '),
      'Clinic Address': filterEmptyValues(rowsForEncounter.map((a) => a['Clinic Address'])).join(', '),

      'Payor Category': getPayorCategory(targetPayerType || ''),
      'Payer Type': targetPayerType || '',
      InsuranceID: fetchValueFromMultipleRows('InsuranceID', false),

      'Secondary Payer Type': targetPayerType || '',
      'Secondary InsuranceID': fetchValueFromMultipleRows('InsuranceID', false),

      'Emerg Contact': filterEmptyValues(rowsForEncounter.map((a) => a['Emerg Contact'])).join(', '),
      Ethnicity: fetchValueFromMultipleRows('Ethnicity', true),
      'Emerg Contact Phone': filterEmptyValues(rowsForEncounter.map((a) => a['Emerg Contact Phone'])).join(', '),
      'ED Visit Summary': filterEmptyValues(rowsForEncounter.map((a) => a['ED Visit Summary'])).join(', '),
      Prescriptions: filterEmptyValues(rowsForEncounter.map((a) => a['Prescriptions'])).join(', '),
      Referrals: filterEmptyValues(rowsForEncounter.map((a) => a['Referrals'])).join(', '),
      'Rx Prescribed': getRxPrescribed(fetchValueFromMultipleRows('Rx Prescribed', true)),
    };

    return {
      data: baseDemographics,
      warnings: errorData.errors,
      isProviderReferral: mainEncounter.Identification === 'Provider Referral',
    };
  });
  return mergedData;
};
