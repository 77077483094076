import { SymptoData } from '../types';
import { SITES } from '../siteData';
import _ from 'lodash';
import { getPayorCategory } from '../csvParsing/genericUtils';
import { ADMIN, ADMIN_SPANISH, REFERRAL, REFERRAL_SPANISH, SERVICE_INTRO_NO_RESPONSE_SPANISH, SERVICE_INTRO_NO_RESPONSE } from './introMessageCopy';

export const generateMessageData = (patientData: Omit<SymptoData, 'Intro Message' | 'Full Site Name'>) => {
  const site = SITES.find(site => site.value === patientData.Sites);
  if (site === undefined) throw new Error('Site not found');
  const pcpName = patientData['PCP Provider Name'] && patientData['PCP Provider Name'].trim().length > 0
    && !patientData['PCP Provider Name'].toLowerCase().includes('unknown')
    && !patientData['PCP Provider Name'].toLowerCase().includes('other,other')
    && !patientData['PCP Provider Name'].toLowerCase().includes('none,none')
    ? _.capitalize(patientData['PCP Provider Name'])
    : null;
  const navigatorFirstName = patientData['Navigator Name'].split(' ')[0];
  const insurance = patientData['Payer Type'];
  const isSpanish = patientData['Spanish Speaking'] === 'TRUE';
  const patientFirstName = patientData['First Name'];
  const { label: siteName } = site;
  const messageData = {
    patientFirstName,
    navigatorFirstName,
    siteName,
    pcpName,
    isUninsured: getPayorCategory(insurance) === 'Uninsured / Self-Pay',
    insurance,
  }
  if (patientData.Identification === 'Service Intro No Response' && messageData.isUninsured) {
    return isSpanish ? SERVICE_INTRO_NO_RESPONSE_SPANISH(messageData) : SERVICE_INTRO_NO_RESPONSE(messageData)
  }
  if (patientData.Identification === 'Administrator') {
    return isSpanish ? ADMIN_SPANISH(messageData) : ADMIN(messageData)
  }
  if (patientData.Identification === 'Provider Referral') {
    return isSpanish
      ? REFERRAL_SPANISH(messageData)
      : REFERRAL(messageData);
  }
  return '';
};
