import React, { useMemo } from 'react'
import _ from 'lodash';
import cx from 'classnames';
import { Button } from 'react-bootstrap';
import fileDownload from 'js-file-download';
import { type MergedDataT } from "../mergedColumns/mergeData";
import CardItem from '../utils/CardItem';
import { NavigatorSchemaT } from "../navAssignment/navOptions";
import { SITES_WITH_SITE_CODE } from '../siteData';
import './index.scss';


const NavigatorAnalytics = ({
  mergedData,
  navigatorAssignments,
}: {
  mergedData: MergedDataT[],
  navigatorAssignments: NavigatorSchemaT[],
}) => {
  const analytics = useMemo(() => {
    const allWarnings = _.uniq(_.flatten(mergedData.map(({ warnings }) => (warnings || []))));
    const warningFrequency = allWarnings.map((warning) => ({
      title: `Warning: ${warning}`,
      value: mergedData.filter(({ warnings }) => (warnings || []).includes(warning)).length,
    }));
    return [
      {
        title: 'Total Number of Patients',
        value: mergedData.length,
      },
      ...warningFrequency,
    ];
  }, [mergedData]);

  return (
    <>
      <CardItem className="NavigatorAnalytics bg-white mt-5" title="Navigator Analytics">
        {
          analytics.map(({ title, value }) => (
            <div className="mb-2">
              <div className="text-smaller font-weight-bold mb-1">{`${title}: `}</div>
              <div className="text-small">{value}</div>
            </div>
          ))
        }
        <Button
          onClick={() => {
            const str = JSON.stringify(mergedData
              .map(({ data }) => data));
            const bytes = new TextEncoder().encode(str);
            const blob = new Blob([bytes], {
                type: "application/json;charset=utf-8"
            });

            fileDownload(
              blob,
              `sympto-parsed-data-${new Date().toISOString()}.json`,
            );
          }}
          variant="primary"
          className="text-white"
        >
          Download JSON
        </Button>
      </CardItem>
      <CardItem className="NavAnalytics bg-white mt-5" title="Navigator Analytics">
        {
          (SITES_WITH_SITE_CODE.map(({
              value
            }) => ([{
              title: `# of Referrals for ${value}`,
              value: mergedData.filter((d) => d.data.Sites === value
                && d.data.Identification === 'Provider Referral'
              ).length,
              dangerIfGreaterThan: false,
            }, {
              title: `Unassigned Referrals`,
              value: mergedData.filter((d) => d.data.Sites === value
                && d.data.Identification === 'Provider Referral'
                && d.data['Navigator Name'].trim().length === 0
              ).length,
              dangerIfGreaterThan: true,
            }, {
              title: `# of Admin Assign.`,
              value: mergedData.filter((d) => d.data.Sites === value
                && d.data.Identification === 'Administrator'
              ).length,
              dangerIfGreaterThan: false,
            }, {
              title: `# of Service Intro Assign.`,
              value: mergedData.filter((d) => d.data.Sites === value
                && d.data.Identification === 'Service Intro No Response'
              ).length,
              dangerIfGreaterThan: false,
            }]))).map((data) => (
              <div className="d-flex align-items-center justify-content-between">
                {data.map(({ title, value, dangerIfGreaterThan }) => (
                <div className={cx('mb-2', {
                  'text-danger': dangerIfGreaterThan && value > 0,
                })}>
                  <div className="text-smaller font-weight-bold mb-1">{`${title}: `}</div>
                  <div className="text-small">{value}</div>
                </div>
                ))}
              </div>
          ))
        }
        <hr />
      </CardItem>
    </>
  );
};

export default NavigatorAnalytics;
