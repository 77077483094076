import { SiteTypesT } from '../types';

export const LOW_PRI_NAVS = [
  'Jenny Escamilla',
  'Yadira Villata',
  'Nazareth Bereket',
  'Prithvi Narasimhan',
  'Alisha Abate',
  'Rachel George',
];

export type NavigatorSchemaT = {
  navigatorName: string,
  prioritizeSpanish: boolean,
  chfPatients: boolean,
  sites: SiteTypesT[],
  referralCount: number,
  adminCount: number,
};

export const DEFAULT_NAV_OPTIONS: NavigatorSchemaT[] = [
  'Nadine Thomas',
  'Jayde Nguyen',
  'Garrett Chamberlain',
  'Emilio Calderon',
  'Iliana De La Garza',
  'Ruby Carnalla',
  'Jasmine Johnson',
  'Michael Assefa',
  'Vanessa Ramos',
  'Karime Prado',
  'Christina Robledo',
  'Diamond Woods',
  'Vania McFadden',
  'L\'Sidni Helm',
  ...LOW_PRI_NAVS,
].map((navigatorName) => ({
  navigatorName,
  prioritizeSpanish: false,
  sites: [],
  chfPatients: false,
  referralCount: 0,
  adminCount: 0,
}))
