import { NavigatorSchemaT } from "../navAssignment/navOptions";
import PatientListManager from "./patientListManager";
import { SymptoData } from "../types";
import { LOW_PRI_NAVS } from "../navAssignment/navOptions";
import { UnassignedMergedDataT } from "./mergeDuplicates";
import { SERVICE_INTRO_ENCOUNTER_TEXT  } from "../csvParsing/genericUtils";
import { generateMessageData } from './introMessage';

const hydratePatientFields = (
  patient: UnassignedMergedDataT,
  navigator: NavigatorSchemaT,
  type: 'referral' | 'admin',
): {
  data: SymptoData;
  warnings: null | string[];
} => {
  const basePatientData = {
    ...patient.data,
    Identification: (type === 'referral'
      ? 'Provider Referral'
      : 'Administrator' as SymptoData['Identification']),
    'No Service Introduction': 'TRUE' as 'TRUE' | 'FALSE',
    'Encounter Details/Comments': patient.data['Encounter Details/Comments'].replaceAll(SERVICE_INTRO_ENCOUNTER_TEXT, ''),
    'Navigator Name': navigator.navigatorName,
    'Secondary Navigator Name': LOW_PRI_NAVS.includes(navigator.navigatorName)
        ? 'L\'Sidni Helm'
        : '',
  };

  return {
    data: {
      ...basePatientData,
      'Intro Message': generateMessageData(basePatientData),
    },
    warnings: patient.warnings,
  }
};

export const assignPatientToNav = (
  patientLists: PatientListManager,
  hasSpanishSpeakingAvailable: {
    referralCount: number,
    adminCount: number,
  },
  navigator: NavigatorSchemaT,
): null | {
  updatedNavigatorData: NavigatorSchemaT,
  patient:  {
    data: SymptoData;
    warnings: null | string[];
  },
} => {
  if (navigator.referralCount > 0 && navigator.chfPatients) {
    const targetPatient = patientLists.extractCHFPatient(navigator.sites);
    if (targetPatient != null) {
      return {
        updatedNavigatorData: {
          ...navigator,
          referralCount: navigator.referralCount - 1,
        },
        patient: hydratePatientFields(targetPatient, navigator, 'referral'),
      };
    }
  }
  if (navigator.referralCount > 0 && navigator.prioritizeSpanish) {
    const spanishPt = patientLists.extractSpanishReferral(navigator.sites)
    if (spanishPt != null) {
      return {
        updatedNavigatorData: {
          ...navigator,
          referralCount: navigator.referralCount - 1,
        },
        patient: hydratePatientFields(spanishPt, navigator, 'referral'),
      };
    }
  }
  if (navigator.referralCount > 0) {
    const targetPatient = hasSpanishSpeakingAvailable.referralCount > 0
      ? patientLists.extractEnglishReferral(navigator.sites)
      : patientLists.extractReferral(navigator.sites);
    if (targetPatient != null) {
      return {
        updatedNavigatorData: {
          ...navigator,
          referralCount: navigator.referralCount - 1,
        },
        patient: hydratePatientFields(targetPatient, navigator, 'referral'),
      };
    }
  }
  if (navigator.prioritizeSpanish && navigator.adminCount > 0) {
    const spanishRef = patientLists.extractAdmin(navigator.sites);
    if (spanishRef != null) {
      return {
        updatedNavigatorData: {
          ...navigator,
          adminCount: navigator.adminCount - 1,
        },
        patient: hydratePatientFields(spanishRef, navigator, 'admin'),
      };
    }
  }
  if (navigator.adminCount > 0) {
    const targetPatient = hasSpanishSpeakingAvailable.adminCount > 0
      ? patientLists.extractEnglishAdmin(navigator.sites)
      : patientLists.extractAdmin(navigator.sites);
    return targetPatient != null ? {
      updatedNavigatorData: {
        ...navigator,
        adminCount: navigator.adminCount - 1,
      },
      patient: hydratePatientFields(targetPatient, navigator, 'admin'),
     } : null;
  }
  return null;
};
