type MessageData = {
  patientFirstName: string,
  navigatorFirstName: string,
  siteName: string,
  insurance: string,
  pcpName: string | null
  isUninsured: boolean,
}

export const ADMIN = (data: MessageData) => `Hi ${data.patientFirstName}\n
I'm ${data.navigatorFirstName} - your patient care navigator from ${data.siteName}.
I hope you're feeling better after your recent hospital visit.
I am reaching out to assist you with scheduling your follow up appointment, and can help to answer any questions you may have about insurance, transportation to appointments, local resources and overall next steps.

To get started, I have a few questions for you:
1) Please confirm your Date of Birth.
2) What is your typical availability for a medical appointment on weekdays?
3) ${data.pcpName != null
  ? `Please confirm the name and/or clinic location of your PCP. We have ${data.pcpName} listed in our chart.`
  : 'Do you have a primary care provider? If so, please provide their name'}
4) We have your insurance listed as ${data.insurance}. Is this correct? ${data.isUninsured
  ? `If so, we can assist you with checking which insurance plans and benefits you'd be eligible for at no cost to you! Please complete the screening form using the following link -> RelyHealth.312Help.com\n
  A dedicated team member from the benefits team will contact you to assist with the final steps once we receive it.`
  : ''}

I'll also follow up over the phone shortly, but if you have questions, text back here or call us at 312-319-2490.
The goal is to ensure that you receive the best care possible, so please keep an eye out for our call.

Thank you and have a great day.

In the case of an emergency, please dial 911 immediately.
Para Espanol, responda "Espanol". En caso de una emergencia, llame al 911 inmediatamente.`;


export const ADMIN_SPANISH = (data: MessageData) => `Hola ${data.patientFirstName}\n
Soy ${data.navigatorFirstName} - tu coordinador(a) de cuidado del paciente de ${data.siteName}.
Espero que te sientas mejor después de tu reciente visita al hospital.
Me pongo en contacto contigo para ayudarte a programar tu cita de seguimiento y para responder cualquier pregunta que puedas tener sobre tu seguro médico, transporte para las citas, recursos locales y los próximos pasos a seguir.

Para comenzar, tengo algunas preguntas para ti:
1) Por favor, confirma tu fecha de nacimiento.
2) ¿Cuál es tu disponibilidad típica para una cita médica durante la semana?
3) ${data.pcpName != null
  ? `Por favor, confirma el nombre y/o la ubicación de tu clínica de atención primaria. Tenemos a ${data.pcpName} registrado en nuestro sistema.`
  : '¿Tienes un médico de atención primaria? Si es así, por favor proporciona su nombre'}
4) Tenemos registrado que tu seguro medico es ${data.insurance}. ¿Es correcto? ${data.isUninsured
  ? `Si es así, ¡podemos ayudarte a verificar  planes de seguro médico y beneficios elegibles para ti sin ningún costoi! Por favor, completa el formulario de evaluación utilizando el siguiente enlace -> RelyHealth.312Help.com\n
  Un miembro dedicado de nuestro equipo de beneficios se pondrá en contacto contigo para ayudarte con los pasos finales una vez que recibamos tu solicitud.`
  : ''}

También te llamaré pronto, pero si tienes preguntas, responde aquí o llámanos al 312-319-2490.
Nuestro objetivo es asegurarnos de que recibas la mejor atención posible, así que por favor, espera nuestra llamada.

Gracias y que tengas un buen día.
En caso de una emergencia, llame al 911 inmediatamente.`;

export const REFERRAL = (data: MessageData) => `Hi ${data.patientFirstName}\n
I'm ${data.navigatorFirstName} - your patient care navigator from ${data.siteName}. Your ER care team referred you to our team to schedule your follow-up care and assist you after your recent visit.\n

To get started, I have a few questions for you:
1) Please confirm your date of birth.
2) What is your typical availability on weekdays?
3) ${data.pcpName != null
  ? `Please confirm the name and/or clinic location of your PCP. We have ${data.pcpName} listed in our chart.`
  : 'Do you have a primary care provider? If so, please provide their name'}
4) We have your insurance listed as ${data.insurance}. Is this correct? ${data.isUninsured
  ? `If so, we can assist you with checking which insurance plans and benefits you'd be eligible for at no cost to you! Please complete the screening form using the following link -> RelyHealth.312Help.com\n
  A dedicated team member from the benefits team will contact you to assist with the final steps once we receive it.`
  : ''}

I'll also give you a call shortly but if you have questions, text back here or call us at (312) 319-2490 M-F | 8a-6p CST. The goal is to ensure that you receive the best care possible, so please keep an eye out for our call. Thank you, and have a great day.\n
In the case of an emergency, please dial 911 immediately.\n
Para Espanol, responda "Espanol". En caso de una emergencia, llame al 911 inmediatamente`;

export const REFERRAL_SPANISH = (data: MessageData & { pcpName: string | null }) => `Hola ${data.patientFirstName}\n
Soy ${data.navigatorFirstName} - tu coordinador(a) de cuidado del paciente de ${data.siteName}. Tu equipo de atención de urgencias te ha referido a nuestro equipo para programar tu seguimiento y ayudarte después de tu reciente visita.\n

Para empezar, tengo algunas preguntas para ti:
1) Por favor, confirma tu fecha de nacimiento.
2) ¿Cuál es tu disponibilidad durante la semana?
3) ${data.pcpName != null
  ? `Por favor, confirma el nombre y/o la ubicación de tu clínica de atención primaria. Tenemos a ${data.pcpName} registrado en nuestro sistema.`
  : '¿Tienes un médico de atención primaria? Si es así, por favor proporciona su nombre'}
4) Tenemos registrado que tu seguro medico es ${data.insurance}. ¿Es correcto? ${data.isUninsured
  ? `Si es así, ¡podemos ayudarte a verificar  planes de seguro médico y beneficios elegibles para ti sin ningún costoi! Por favor, completa el formulario de evaluación utilizando el siguiente enlace -> RelyHealth.312Help.com\n
  Un miembro dedicado de nuestro equipo de beneficios se pondrá en contacto contigo para ayudarte con los pasos finales una vez que recibamos tu solicitud.`
  : ''}

También te llamaremos pronto, pero si tienes preguntas, responde aquí o llámanos al (312) 319-2490 de L-V | 8a-6p CST. Nuestro objetivo es asegurarnos de que recibas la mejor atención posible, así que, por favor, espera nuestra llamada. Gracias y que tengas un buen día.\n
En caso de emergencia, por favor marca 911 inmediatamente.`;

export const SERVICE_INTRO_NO_RESPONSE = (data: MessageData) => `Hi ${data.patientFirstName}!\n
We have your insurance documented as ${data.insurance}. Is that correct?\n
If so, we can assist you with checking which insurance plans and benefits you'd be eligible for at no cost to you!
Please complete the screening form using the following link -> RelyHealth.312Help.com
A dedicated team member from the benefits team will contact you to assist with the final steps once we receive it.

Let me know if you have any questions. Talk soon! In the case of an emergency, please dial 911 immediately
Para Español, responda "Español"`;

export const SERVICE_INTRO_NO_RESPONSE_SPANISH = (data: MessageData) => `Hola ${data.patientFirstName}!

Hemos documentado tu seguro como ${data.insurance}. ¿Es  correcto?

Si es así, ¡podemos ayudarte a verificar  planes de seguro médico y beneficios elegibles para ti sin ningún costoi!
Por favor, completa el formulario de evaluación utilizando el siguiente enlace -> RelyHealth.312Help.com

Un miembro dedicado de nuestro equipo de beneficios se pondrá en contacto contigo para ayudarte con los pasos finales una vez que recibamos tu solicitud.
Si tienes preguntas, envíanos un mensaje de texto o llámanos al (312) 319-2490 de L-V | 8a-6p CST. Tu salud y bienestar son nuestra prioridad.

En casos de emergencia, por favor marca el 911.`;
