import _ from "lodash";
import { UnassignedMergedDataT } from "./mergeDuplicates";
import { SiteTypesT } from "../types";

type PatientLists = {
  spanishReferrals: UnassignedMergedDataT[];
  englishReferrals: UnassignedMergedDataT[];
  spanishAdmin: UnassignedMergedDataT[];
  englishAdmin: UnassignedMergedDataT[];
  chfPatients: UnassignedMergedDataT[];
};

const findPatientBySite = (list: UnassignedMergedDataT[], sitesAvailable: SiteTypesT[]) => {
  const targetPatient = list.find((patient) => {
    return sitesAvailable.includes(patient.data.Sites);
  });
  const listWithoutTargetPatient = list.filter((patient) => {
    return !_.isEqual(patient, targetPatient);
  });
  return { targetPatient, listWithoutTargetPatient };
}

const numPatientsInSite = (list: UnassignedMergedDataT[], sitesAvailable: SiteTypesT[]) => (
  list.filter((patient) => (
    sitesAvailable.includes(patient.data.Sites)
  )).length);

class PatientListManager {
  patientLists: PatientLists;

  constructor (patientLists: PatientLists) {
    this.patientLists = patientLists;
  }

  getRemainingAdminPatients () {
    return [
      ...this.patientLists.spanishAdmin,
      ...this.patientLists.englishAdmin,
    ];
  }

  hasUnassignedReferrals (): {
    numPatients: number,
    site: SiteTypesT,
  }[] {
    return _.compact(
      _.toPairs(_.groupBy([
        ...this.patientLists.spanishReferrals,
        ...this.patientLists.englishReferrals,
      ], 'data.Sites'))
      .map(([site, patients]) => (
        patients.length > 0
          ? {
            numPatients: patients.length,
            site: site as SiteTypesT,
          }
          : null
      ))
    );
  }

  hasUnassignedCHFPatients (): {
    numPatients: number,
    site: SiteTypesT,
  }[] {
    return _.compact(
      _.toPairs(_.groupBy(this.patientLists.chfPatients, 'data.Sites'))
      .map(([site, patients]) => (
        patients.length > 0
          ? {
            numPatients: patients.length,
            site: site as SiteTypesT,
          }
          : null
      ))
    );
  }

  extractCHFPatient (sitesAvailable: SiteTypesT[]): UnassignedMergedDataT | null {
    const chfList = findPatientBySite(this.patientLists.chfPatients, sitesAvailable);
    if (chfList.targetPatient != null) {
      this.patientLists.chfPatients = chfList.listWithoutTargetPatient;
      return chfList.targetPatient;
    }
    return null;
  }

  extractReferral (sitesAvailable: SiteTypesT[]): UnassignedMergedDataT | null {
    // only want to include list if there are patients in the site avaialable
    // for assignment
    const langOptions = _.compact([
      numPatientsInSite(this.patientLists.spanishReferrals, sitesAvailable) > 0 ? 'Spanish' : null,
      numPatientsInSite(this.patientLists.englishReferrals, sitesAvailable) > 0 ? 'English' : null,
    ]);
    return _.sample(langOptions) === 'Spanish'
      ? this.extractSpanishReferral(sitesAvailable)
      : this.extractEnglishReferral(sitesAvailable);
  }


  extractAdmin (sitesAvailable: SiteTypesT[]): UnassignedMergedDataT | null {
    // only want to include list if there are patients in the site avaialable
    // for assignment
    const langOptions = _.compact([
      numPatientsInSite(this.patientLists.spanishAdmin, sitesAvailable) > 0 ? 'Spanish' : null,
      numPatientsInSite(this.patientLists.englishAdmin, sitesAvailable) > 0 ? 'English' : null,
    ]);
    return _.sample(langOptions) === 'Spanish'
      ? this.extractSpanishAdmin(sitesAvailable)
      : this.extractEnglishAdmin(sitesAvailable);
  }

  extractEnglishReferral (sitesAvailable: SiteTypesT[]): UnassignedMergedDataT | null {
    const referralTargets = findPatientBySite(this.patientLists.englishReferrals, sitesAvailable);
    if (referralTargets.targetPatient != null) {
      this.patientLists.englishReferrals = referralTargets.listWithoutTargetPatient;
      return referralTargets.targetPatient;
    }
    return null;
  }

  extractSpanishReferral (sitesAvailable: SiteTypesT[]): UnassignedMergedDataT | null {
    const referralTargets = findPatientBySite(this.patientLists.spanishReferrals, sitesAvailable);
    if (referralTargets.targetPatient != null) {
      this.patientLists.spanishReferrals = referralTargets.listWithoutTargetPatient;
      return referralTargets.targetPatient;
    }
    return null;
  }

  extractEnglishAdmin (sitesAvailable: SiteTypesT[]): UnassignedMergedDataT | null {
    const referralTargets = findPatientBySite(this.patientLists.englishAdmin, sitesAvailable);
    if (referralTargets.targetPatient != null) {
      this.patientLists.englishAdmin = referralTargets.listWithoutTargetPatient;
      return referralTargets.targetPatient;
    }
    return null;
  }

  extractSpanishAdmin (sitesAvailable: SiteTypesT[]): UnassignedMergedDataT | null {
    const referralTargets = findPatientBySite(this.patientLists.spanishAdmin, sitesAvailable);
    if (referralTargets.targetPatient != null) {
      this.patientLists.spanishAdmin = referralTargets.listWithoutTargetPatient;
      return referralTargets.targetPatient;
    }
    return null;
  }
}

export default PatientListManager;
