import React, { useState } from 'react'
import Swal from 'sweetalert2';
import _ from 'lodash';
import FileListItem, { fetchErrors } from './FileListItem';
import FileUploadItem, { type FileUploadDataT } from './FileUploadItem';
import CardItem from '../utils/CardItem';
import { Message, Blocks } from 'slack-block-builder';
import { sendToSlack } from '../SlackMessage';
import './index.scss';


const FileUpload = ({
   onUpload,
   uploadFinished,
}: {
  onUpload: (data: FileUploadDataT[]) => void,
  uploadFinished: boolean,
}) => {
  const [currentFiles, setCurrentFiles] = useState<FileUploadDataT[]>([]);
  const [filesUploading, setFilesUploading] = React.useState(0);
  return (
    <CardItem
      className="FileUpload bg-white mx-5 mt-5"
      title="Upload CSVs / Excel Files"
      saveOpts={uploadFinished ? null : {
        onSave: async () => {
          if (filesUploading) {
            Swal.fire({
              title: 'Files still uploading',
              text: 'Please wait for all files to finish uploading before saving',
              icon: 'error',
            });
            return;
          }
          if (await sendToSlack(Message({
            text: 'Skipped Patients from CSV Upload',
          })
            .blocks(
              Blocks.Section({
                text: 'The following patients were skipped from the CSV upload for non-ed discharge reasons...',
              }),
              ...currentFiles
                .filter((file) => fetchErrors(file, false).length > 0)
                .map((file) => ([
                  Blocks.Header({
                    text: file.fileName,
                  }),
                  ..._.flatten((fetchErrors(file, false).map(({ errorMessage, siteCount, totalPatients }) =>  ([
                    Blocks.Section({
                      text: `*${errorMessage}* - ${totalPatients} pts`,
                    }),
                    Blocks.Context().elements(
                      ...Object.keys(siteCount).map((site) => (
                        `${site}: ${siteCount[site]}`
                      )),
                    ),
                  ])))),
                  Blocks.Divider(),
                ]),
              )))) {
            onUpload(currentFiles);
          }
        },
        saveText: filesUploading ? 'Files still uploading...' : 'Save and publish errors to Slack',
      }}
    >
      <div className="d-flex align-items-start justify-content-start flex-wrap">
        {
          currentFiles.map((file) => (
            <FileListItem
              key={file.id}
              currentData={file}
              onClear={() => {
                const updatedFiles = currentFiles.filter((f) => f.id !== file.id);
                setCurrentFiles(updatedFiles);
              }}
            />
          ))
        }
      </div>
      {!uploadFinished && (
        <FileUploadItem
          setFilesUploading={setFilesUploading}
          filesUploading={filesUploading}
          onUpload={(data) => {
            setCurrentFiles((curFiles) => ([
              ...curFiles,
              data,
            ]));
          }}
        />
      )}
    </CardItem>
  );
};

export default FileUpload;
