import _ from 'lodash';
import moment from 'moment';
import { type MMDDYYYYFormat, type SiteTypesT } from '../types';
import { validateAndFormatPhoneNumber } from './phoneValidate';
import { type ExtractRespT } from './genericUtils';
import { getRace, getGender, getEthnicity } from './genericUtils'

const ALLOWED_SITES: Record<string, SiteTypesT> = {
  'AMITA HEALTH Elgin': 'I14',
  'AMITA HEALTH Joliet': 'I22',
  'AMITA HEALTH Kankakee': 'I23',
  'AMITA HEALTH Aurora': 'I15',
};

export const extractSite = (row: Record<string, string>): ExtractRespT<{
  Sites: SiteTypesT,
}> => {
  const siteName = row['Site_Name'];
  if (siteName.trim().length === 0) {
    return { type: 'error', error: 'Site name is empty' };
  }
  const site = ALLOWED_SITES[siteName];
  if (site === undefined) {
    return { type: 'error', error: `Site name "${siteName}" is not allowed` };
  }
  return { type: 'ok', data: { Sites: site }, warning: null };
};

const extractPhoneNumber = (row: Record<string, string>): ExtractRespT<{
  'Phone Number': string,
  'Alternate Phone #': string,
}> => {
  const validPhoneNumbers = _.uniq(['Alternate_Phone1', 'Alternate_Phone2', 'Primary_Phone']
    .map((phone) => validateAndFormatPhoneNumber(row[phone]))
    .filter((phone) => phone.status === 'ok'));

  // the difefrent phone number optiosn by priroity - see if any match
  const validPhoneNumber = _.head(validPhoneNumbers);
  const alternativePhoneNumber = validPhoneNumbers.find((phone) => !_.isEqual(phone, validPhoneNumber));

  return {
    type: 'ok',
    data: {
      'Phone Number': validPhoneNumber != null && validPhoneNumber.status === 'ok' ? validPhoneNumber.phoneNumber : '',
      'Alternate Phone #': alternativePhoneNumber != null && alternativePhoneNumber.status === 'ok'? alternativePhoneNumber.phoneNumber : '-',
    },
    warning: validPhoneNumber == null || validPhoneNumber.status !== 'ok'
      ? [`Invalid cell phone number. Invalid options ${row['Alternate_Phone1']}, ${row['Primary_Phone']}, ${row['Alternate_Phone2']}`]
      : [],
  }
}

export const extractDemographics = (row: Record<string, string>): ExtractRespT<{
  'First Name': string,
  'Last Name': string,
  MRN: string,
  'Date of Birth': MMDDYYYYFormat,
  'Encounter Number': string,
  Sites: SiteTypesT,
  'Phone Number': string,
  'Alt Phone #': string,
  Address: string,
  Sex: string,
  Gender: string,
  Race: string,
  'Emerg Contact': string,
  'Emerg Contact Phone': string,
  'Is Pregnant?': 'Yes' | 'No' | 'Unknown',
  'PCP Medical Group': string,
  'PCP Provider Name': string,
  'Chief Complaint': string,
  'PCP Phone': string,
  'Discharge Diagnosis':  string,
  InsuranceID: string,
  'Payer Type': string,
  Ethnicity: string,
  'Provider NPI': string,
  'Provider Specialty': string
  'Clinic Address': string,
  'Clinic Name': string,
  'ED Visit Summary': string,
  Prescriptions: string,
  Referrals: string,
  'Email Address': string,
  'Rx Prescribed': string,
  'PCP Extraction': string,
  'Secondary Payer Type': string,
  'Secondary InsuranceID': string,
}> => {
  const firstName = _.capitalize(row['Patient_First_Name']);
  const warnings = [];
  if (firstName.trim().length === 0) {
    warnings.push('First name is empty');
  }
  const lastName = _.capitalize(row['Patient_Last_Name']);
  if (lastName.trim().length === 0) {
    warnings.push('Last name is empty');
  }
  const dob = row['Date_of_Birth'];
  const formattedDOB = moment(dob, 'MM/DD/YYYY', true);
  if (!formattedDOB.isValid()) {
    return { type: 'error', error: `Date of birth "${dob}" is invalid` };
  }
  const mrn = row['MRN'];
  if (mrn == null ||  mrn.trim().length === 0) {
    return { type: 'error', error: 'MRN is empty' };
  }
  const encounterNumber = row['AccountNumber'];
  if (encounterNumber.trim().length === 0) {
    return { type: 'error', error: 'Encounter number is empty' };
  }

  const siteResp = extractSite(row);
  if (siteResp.type === 'error') {
    return siteResp;
  }
  if (siteResp.warning !== null) {
    warnings.push(...siteResp.warning);
  }

  const phoneNumberResp = extractPhoneNumber(row);
  if (phoneNumberResp.type === 'error') {
    return phoneNumberResp;
  }
  if (phoneNumberResp.warning !== null) {
    warnings.push(...phoneNumberResp.warning);
  }

  const addressData = `${row['Address1']} ${row['Address2'] != null ? row['Address2'] : ''}, ${row['City']} ${row['State']} ${row['Zip']}`.replaceAll('\n', ' ').replaceAll('\r', '');
  const pregnancyStatus = (() => {
    if (row['Pregnant'] == null || row['Pregnant'].trim().length == null) {
      return 'Unknown';
    }
    if (row['Pregnant'] === 'Yes') {
      return 'Yes';
    }
    if (row['Pregnant'] === 'No') {
      return 'No';
    }
    return 'Unknown';
  })();
  const pcpInformation = (() => {
    const pcpRegex = /; PCP\s*;([^.;]*).*?;/;

    // Get the 'Physician_Documentation' field from the row
    const physicianDocumentation = row['Physician_Documentation'];

    // Check if the 'Physician_Documentation' field exists and is not null
    if (physicianDocumentation) {
      // Extract the PCP information using the regex
      const match = physicianDocumentation?.match(pcpRegex);

      // If a match was found, return the matched group (trimmed of leading/trailing whitespace)
      // Otherwise, return null
      return match ? match[1]?.trim() : null;
    } else {
      // If the 'Physician_Documentation' field doesn't exist or is null, return null
      return null;
    }
  })();

  return {
    type: 'ok',
    data: {
      MRN: mrn,
      'First Name': firstName,
      'Phone Number': phoneNumberResp.data['Phone Number'],
      'Alt Phone #': phoneNumberResp.data['Alternate Phone #'],
      'Email Address': row['Email'],
      Sites: siteResp.data.Sites,
      'Last Name': lastName,
      'Encounter Number': encounterNumber,
      'Date of Birth': formattedDOB.format('MM/DD/YYYY'),
      Address: addressData,
      Ethnicity: getEthnicity(row['Ethnicity']),
      Sex: row['Sex'] != null ? row['Sex'] : '',
      Gender: getGender(row['Gender'], mrn),
      Race: getRace(row['Race'], mrn),
      'Is Pregnant?': pregnancyStatus,
      'Discharge Diagnosis': row['Diagnoses'] != null ? row['Diagnoses'] : '',
      'Chief Complaint': row['CC'] != null ? row['CC'] : '',
      'PCP Provider Name': row['PCP'] != null ? row['PCP'] : '',
      'PCP Phone': row['PCP_Phone'] != null ? row['PCP_Phone'] : '',
      'PCP Extraction': pcpInformation != null ? pcpInformation : '',
      'ED Visit Summary': `${row['Instructions'] || ''}`,
      'Rx Prescribed': row['Rx_Prescribed'] != null ? row['Rx_Prescribed'] : '',
      'Prescriptions': `${row['Prescriptions'] || ''}`,
      'Referrals': `${row['Referrals'] || ''}`,
      'Provider NPI': '',
      'PCP Medical Group': '',
      'Provider Specialty': '',
      'Clinic Address': '',
      'Clinic Name': '',
      'Emerg Contact': row['Secondary_Contact'] != null ? row['Secondary_Contact'] : '',
      'Emerg Contact Phone': row['Secondary_Contact_Phone'] != null ? row['Secondary_Contact_Phone'] : '',
      InsuranceID: row['Primary_InsuranceID'] != null ? row['Primary_InsuranceID'] :row['Primary_Policy_Number'],
      'Payer Type': row['Primary_Insurance_Payor_Name'],
      'Secondary Payer Type': row['Secondary_Insurance_Payor_Name'],
      'Secondary InsuranceID': row['Secondary_InsuranceID'],
    },
    warning: warnings.length > 0 ? warnings : null,
  };
};
