import moment from 'moment';
import _ from 'lodash';
import { SymptoData } from '../types';
import { extractDemographics } from './meditechUtils';
import { getLanguage } from './genericUtils';
import { generateNavReasons } from './reasonsForNav';

export const formatCSVData = async (data: Record<string, string>): Promise<{
  type: 'error', error: string,
} | {
  type: 'ok', data: Omit<SymptoData, 'Intro Message' | 'Full Site Name' | 'Payor Category' | 'Secondary Navigator Name'>, warnings: Array<string>,
}> => {
  const warnings = [];
  const demographics = extractDemographics(data);
  if (demographics.type === 'error') {
    return { type: 'error', error: demographics.error };
  }
  const { data: demographicsData, warning: demographicsWarning } = demographics;
  warnings.push(...(demographicsWarning || []));


  const parsedEDReferralDate = moment(data['Arr_Date'], 'MM/DD/YYYY', true)
  if (!parsedEDReferralDate.isValid()) {
    warnings.push(`Invalid ED Arrival Date: ${data['Arr_Date']}`);
  }
  const encounterDetails = `${data['Reason_For_Referral'] || ''} ${data['Referral_Comments'] || ''}`;

  const {
    language, isSpanishSpeaking, additionalEncounterDetailForLanguage,
  } = ((): {
    language: string,
    isSpanishSpeaking: boolean,
    additionalEncounterDetailForLanguage: string,
  } => {
    const languageSelection = (data['Language'] || '').trim();
    const isSpanishInEncounter = encounterDetails.toUpperCase().includes('SPANISH');
    const formattedLanguage = languageSelection.length > 0 ? _.capitalize(languageSelection) : 'English';
    if (isSpanishInEncounter && formattedLanguage === 'English') {
      warnings.push(`Patient has Spanish in encounter details but language is English. Overriding language to Spanish`);
      return {
        language: 'Spanish',
        isSpanishSpeaking: true,
        additionalEncounterDetailForLanguage: 'Patient has Spanish in encounter details but language in EMR is English. Verify preferred language with patient.'
      };
    }
    if (isSpanishInEncounter && formattedLanguage !== 'Spanish') {
      warnings.push(`Patient has Spanish in encounter details but language is ${formattedLanguage}. Using ${formattedLanguage}`);
      return {
        language: formattedLanguage,
        isSpanishSpeaking: true,
        additionalEncounterDetailForLanguage: `Patient has Spanish in encounter details but language in EMR is non-English but ${formattedLanguage}. Verify preferred language with patient.`,
      };
    }
    return {
      language: formattedLanguage,
      isSpanishSpeaking: formattedLanguage === 'Spanish',
      additionalEncounterDetailForLanguage: '',
    };
  })();
  const { navReasons, priority, additionalEncounterDetailComments } = generateNavReasons({
    parsedEncounters: encounterDetails || '',
    site: demographicsData.Sites,
    dischargeDiagnoses: demographicsData['Discharge Diagnosis'],
    chiefComplaint: demographicsData['Chief Complaint'],
    insuranceType: data['Primary_Insurance_Payor_Name'],
    numEDVisits: Number(data['Visits_6_Months'].trim().length > 0 ? (data['Visits_6_Months']) : 0),
  })

  return {
    type: 'ok',
    data: {
      'ED Visit/Referral Date': moment(data['Arr_Date'], 'MM/DD/YYYY').format('MM/DD/YYYY'),
      'Provider / On-Site Coordinator Name': data['Referring_Provider'],
      "Patient's Primary Language": getLanguage(language, demographicsData.MRN),
      'Spanish Speaking': isSpanishSpeaking ? 'TRUE' : 'FALSE',
      'Reason for Navigation': navReasons.join(','),
      'Priority Level': priority,
      'Encounter Details/Comments': `${encounterDetails || ''}\n${additionalEncounterDetailComments || ''}\n${additionalEncounterDetailForLanguage || ''}`,
      'Navigator Name': '',
      'Visits within 6 mo\'s': String(data['Visits_6_Months'].trim().length > 0 ? (data['Visits_6_Months']) : 0),
      Identification: 'Provider Referral',
      'No Service Introduction': 'TRUE',
      ...demographicsData,
    },
    warnings,
  };
};
