import _ from "lodash";
import { UnassignedMergedDataT } from "./mergeDuplicates";
import { TARGET_INSURANCE_CATEGORIES, getPayorCategory } from "../csvParsing/genericUtils";
import PatientListManager from "./patientListManager";
import { REASONS }  from '../csvParsing/reasonsForNav';

export const generateColumns = (allPatients: UnassignedMergedDataT[]): PatientListManager => {
  const spanishReferrals: UnassignedMergedDataT[] = [];
  const englishReferrals: UnassignedMergedDataT[] = [];
  const spanishAdmin: UnassignedMergedDataT[] = [];
  const englishAdmin: UnassignedMergedDataT[]= [];
  const chfPatients: UnassignedMergedDataT[] = [];
  allPatients.forEach((row) => {
    if (row.data['Reason for Navigation'].includes(REASONS.CHF_PILOT)) {
      chfPatients.push(row);
    } else if (row.isProviderReferral) {
      if (row.data['Spanish Speaking'] === 'TRUE') {
        spanishReferrals.push(row);
      } else {
        englishReferrals.push(row);
      }
    } else {
      if (row.data['Spanish Speaking'] === 'TRUE') {
        spanishAdmin.push(row);
      } else {
        englishAdmin.push(row);
      }
    }
  });
  const sortList = (rows: UnassignedMergedDataT[]) => {
    // sort by High utilizers (Visits within 6 mo\'s) > 2 first
    // then by insuarnce if payor type in TARGET_INSURANCE_CATEGORIES
    return _.sortBy(
      _.sortBy(
        rows,
        // Sort by Payor Category as Medicaid/Medicare.
        (row) => {
          return TARGET_INSURANCE_CATEGORIES.includes(getPayorCategory(_.head(row.data['Payer Type']) ?? '')) ? 0 : 1;
        }
      ),
      // Then prioritize patients with 'Visits within 6 mo's' greater than 2.
      (row) => (parseInt(row.data['Visits within 6 mo\'s'], 10) > 2 ? 0 : 1),
    );
  };
  return new PatientListManager({
    spanishReferrals: sortList(spanishReferrals),
    englishReferrals: sortList(englishReferrals),
    spanishAdmin: sortList(spanishAdmin),
    englishAdmin: sortList(englishAdmin),
    chfPatients: sortList(chfPatients),
  });
};
