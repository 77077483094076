import React from 'react'
import cx from 'classnames';

const PreviewModal = ({
  csvData: { data, errors },
  headers,
}: {
  csvData: {
    data: Record<string, string>,
    errors: null | string[],
  },
  headers: string[],
}) => (
  <>
    {
      errors && errors.length > 0 && (
        <tr>
          <td style={{ height: '1em' }} className="bg-light border-danger border-bottom border-top-0" colSpan={headers.length}>
          </td>
        </tr>
      )
    }
    <tr
      className={cx({
        'border-danger mt-2': errors && errors.length > 0,
      })}
    >
      {headers.map((title) => (
        <td style={{ width: '10em' }}>
          <div>{data[title] || '-'}</div>
        </td>
      ))}
    </tr>
    {errors && errors.length > 0 &&  (
      <>
        <tr className="text-danger text-small bg-white font-weight-bold">
          <td style={{ height: '2em' }} className="bg-white border-danger" colSpan={headers.length}>
            {errors.map((error) => (
              <div>{error}</div>
            ))}
          </td>
        </tr>
        <tr>
          <td style={{ height: '1em' }} className="bg-light border-danger border-top border-bottom-0" colSpan={headers.length}>
          </td>
        </tr>
      </>
    )}
  </>
);

export default PreviewModal;
