import React, { useMemo } from 'react'
import _ from 'lodash';
import cx from 'classnames';
import CardItem from '../utils/CardItem';
import { UnassignedMergedDataT } from './mergeDuplicates';
import { MergedDataT } from "./mergeData";

const AssignmentStats = ({
  numUnassigned,
}: {
  numUnassigned: number,
}) => (
  <span className={cx('text-small', {
    'font-weight-bold text-danger': numUnassigned > 0,
    'text-success': numUnassigned === 0,
  })}>
    {numUnassigned >  0 ? ` (${numUnassigned} unassigned) ` : ' All assigned'}
  </span>
)

const UploadStats = ({
  mergedData,
  assignedData,
}: {
  assignedData: MergedDataT[],
  mergedData: UnassignedMergedDataT[],
}) => {
  const groupedAssignedData = useMemo(() => (
    _.groupBy(assignedData, 'data.Sites')
  ), [assignedData])
  const calculateStats = ({
    unmergedData,
    assignedData,
  }: {
    unmergedData: UnassignedMergedDataT[],
    assignedData: MergedDataT[],
  }) => {
    const filterCHF = (row: UnassignedMergedDataT | MergedDataT) => row.data['Reason for Navigation'].includes('CHF');
    const providerReferrals = unmergedData.filter((row) => row.isProviderReferral && !filterCHF(row)).length;
    const spanishProviderReferrals = unmergedData.filter((row) => row.isProviderReferral && row.data['Spanish Speaking'] === 'TRUE' && !filterCHF(row)).length;
    const admin = unmergedData.filter((row) => !row.isProviderReferral && !filterCHF(row)).length;
    const spanishAdmin = unmergedData.filter((row) => !row.isProviderReferral && row.data['Spanish Speaking'] === 'TRUE' && !filterCHF(row)).length;

    const assignedProviderReferrals = assignedData.filter((row) => row.data.Identification === 'Provider Referral' && !_.isEmpty(row.data['Navigator Name']) && !filterCHF(row)).length;
    const assignedSpanishProviderReferrals = assignedData.filter((row) => row.data.Identification === 'Provider Referral' && row.data['Spanish Speaking'] === 'TRUE' && !_.isEmpty(row.data['Navigator Name']) && !filterCHF(row)).length;
    const assignedAdmin = assignedData.filter((row) => row.data.Identification === 'Administrator' && !_.isEmpty(row.data['Navigator Name']) && !filterCHF(row)).length;
    const assignedSpanishAdmin = assignedData.filter((row) => row.data.Identification === 'Administrator' && row.data['Spanish Speaking'] === 'TRUE' && !_.isEmpty(row.data['Navigator Name']) && !filterCHF(row)).length;

    const chfPatients = unmergedData.filter((row) => row.data['Reason for Navigation'].includes('CHF Pilot')).length;
    const chfAssigned = assignedData.filter((row) => row.data['Reason for Navigation'].includes('CHF Pilot')).length;
    return {
      providerReferrals,
      spanishProviderReferrals,
      admin,
      spanishAdmin,
      unassignedProviderReferrals: providerReferrals - assignedProviderReferrals,
      unassignedSpanishProviderReferrals: spanishProviderReferrals - assignedSpanishProviderReferrals,
      unassignedAdmin: admin - assignedAdmin,
      unassignedSpanishAdmin: spanishAdmin - assignedSpanishAdmin,
      chfPatients,
      unassignedCHF: chfPatients - chfAssigned,
    }
  };

  return (
    <div
      className="w-100"
      style={{
        position: 'sticky',
        top: 0,
        maxHeight: '100vh',
      }}
    >
      <CardItem
        className="w-100 bg-white h-100 overflow-auto"
        title="Upload Stats"
      >
        <div className="d-flex flex-wrap flex-column">
          {
            [
              {
                label: 'All Sites',
                outputs: calculateStats({
                  unmergedData: mergedData,
                  assignedData,
                }),
              },
              ..._.toPairs(_.groupBy(mergedData, 'data.Sites'))
                .map(([label, value]) => ({
                  label,
                  outputs: calculateStats({
                    unmergedData: value,
                    assignedData: groupedAssignedData[label] || [],
                  }),
                })),
            ].map(({ label, outputs }) => (
              <div key={label} className="border text-small  bg-light p-3 mx-3 rounded mb-4">
                <div className="font-weight-bold text-small text-primary mb-2 text-center">
                  {label}
                </div>
                <div className="mb-4">
                  <div className="font-weight-bold text-small text-secondary">
                    # Referrals
                  </div>
                  <div>
                    {outputs.providerReferrals}
                    <span className="text-small text-secondary font-weight-bold text-danger">
                      <AssignmentStats numUnassigned={outputs.unassignedProviderReferrals} />
                    </span>
                    <div className="text-small text-secondary">
                      {`${outputs.spanishProviderReferrals} Spanish`}
                      <span className="text-small text-secondary font-weight-bold text-danger">
                        <AssignmentStats numUnassigned={outputs.unassignedSpanishProviderReferrals} />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <div className="font-weight-bold text-small text-secondary">
                    # Admin
                  </div>
                  <div>
                    {outputs.admin}
                    <span className="text-small text-secondary font-weight-bold text-danger">
                      <AssignmentStats numUnassigned={outputs.unassignedAdmin} />
                    </span>
                    <div className="text-small text-secondary">
                      {`${outputs.spanishAdmin} Spanish`}
                      <AssignmentStats numUnassigned={outputs.unassignedSpanishAdmin} />
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <div className="font-weight-bold text-small text-secondary">
                    # CHF
                  </div>
                  <div>
                    {outputs.chfPatients}
                    <span className="text-small text-secondary font-weight-bold text-danger">
                      <AssignmentStats numUnassigned={outputs.unassignedCHF} />
                    </span>
                  </div>
                </div>
              </div>
            ))
          }

        </div>
      </CardItem>
    </div>
  );
};

export default UploadStats;
