import _ from 'lodash';
import { SymptoData } from '../types';

export type ExtractRespT<T> = {
  data: T,
  type: 'ok',
  warning: null | Array<string>,
} | {
  type: 'error',
  error: string,
};

export const TARGET_INSURANCE_CATEGORIES: Array<SymptoData['Payor Category']> = (
  ['Uninsured / Self-Pay', 'Medicaid', 'Managed Care Plan']
);

export const DEFAULT_PROVIDER = 'Digital Communications Coordinator';

export const SERVICE_INTRO_ENCOUNTER_TEXT = 'Patient was uploaded via Digital Communications Coordinator and received service introduction.'

export const INVALID_ED_DISPOSITION = 'Invalid ED Disposition';

export const getPayorCategory = (payor: string): SymptoData['Payor Category'] => {
  const payorMapping: Record<string, SymptoData['Payor Category'] | null> = {
    "COUNTY CARE HEALTH PLAN MCD REPLACEMENT": "Managed Care Plan",
    "MEDICARE": "Medicare",
    "BLUE CROSS COMMUNITY MCD REPLACEMENT": "Managed Care Plan",
    "BLUE CROSS": "Commercial",
    "BLUE CROSS COMMUNITY MCD MMCP": "Managed Care Plan",
    "Self-Pay": "Uninsured / Self-Pay",
    "SELF PAY": "Uninsured / Self-Pay",
    "MERIDIAN HEALTH MCD REPLACEMENT": "Managed Care Plan",
    "MERIDIAN HEALTH MCD MMCP": "Managed Care Plan",
    "MEDICAID MANAGED CARE": "Medicaid",
    "AETNA BETTER HEALTH MMCP": "Managed Care Plan",
    "AETNA BETTER HEALTH MCD REPLACEMENT": "Managed Care Plan",
    "MEDICAID": "Medicaid",
    "UNITED HEALTHCARE": "Commercial",
    "BLUE CROSS - PPO": "Commercial",
    "MOLINA HEALTHCARE OF IL MCD REPLACEMENT": "Managed Care Plan",
    "MOLINA Healthcare of IL MCD": "Managed Care Plan",
    "Illinois Medicaid": "Medicaid",
    "BLUE CROSS/BLUE SHIELD": "Commercial",
    "BLUE CROSS PPO": "Commercial",
    "MISC WORKERS COMPENSATION": "Other",
    "TRICARE": "Other",
    "AMBETTER": "Commercial",
    "LOCAL1546 HEALTH & WELFARE": "Other",
  };
  const mappedItem = payorMapping[payor];
  if (mappedItem == null && (
    payor.toLowerCase().includes('ppo') || payor.toLowerCase().includes('hmo') || payor.toLowerCase().includes('pos')
    || payor.toLowerCase().includes('united healthcare')
  )) {
    return 'Commercial';
  }

  if (mappedItem == null && (
    payor.toLowerCase().includes('self pay') || payor.toLowerCase().includes('uninsured')
  )) {
    return 'Uninsured / Self-Pay';
  }
  if (mappedItem == null && (
    payor.toLowerCase().includes('medicaid')
  )) {
    return 'Medicaid';
  }
  return mappedItem ?? 'Other';
};

export const getSex = (currentSex: string) => {
  if (currentSex === 'M' || currentSex === 'Male') {
    return 'Male';
  }
  if (currentSex === 'F' || currentSex === 'Female') {
    return 'Female';
  }
  if (currentSex === 'NB' || currentSex === 'Non Binary') {
    return 'Non Binary';
  }
  if (currentSex === 'U' || currentSex === 'Unknown') {
    return 'Unknown';
  }
  alert(`Please add sex ${currentSex} as a dropdown option`);
  return currentSex;
}

export const getLanguage = (language: string, mrn: string) => {
  const allowedLanguges = [
    'English',
    'Spanish',
    'Tagalog',
    'Somali',
    'Farsi',
    'Hindi',
    'Hebrew',
    'Gujarati',
    'Slovak',
    'Cambodian',
    'Arabic',
    'Greek',
    'Italian',
    'Polish',
    'Danish',
    'Russian',
    'Latvian',
    'Hungarian',
    'Ukranian',
    'Croatian',
    'Nepali',
    'Korean',
    'Serbian',
    'Urdu',
    'Vietnamese',
    'Swedish',
    'French',
    'Thai',
    'Romanian',
    'Swahili',
    'Lithuanian',
    'Bosnian',
    'Haitian',
    'Chinese',
    'Amharic',
    'German',
    'Bulgarian',
    'Turkish',
    'Afrikaans',
    'Sign Language',
    'Estonian',
    'Czech',
    'Punjabi',
    'Bengali',
    'Malaysian',
    'Indonesian',
    'Japanese',
    'Telugu',
    'Kanjobal',
    'Portuguese',
  ];

  const targetLang = allowedLanguges.find((allowedLang) => language.toLowerCase().includes(allowedLang.toLowerCase()));
  if (targetLang != null) {
    return targetLang;
  }
  if (language === 'Unknown' || language === 'Other' || language.trim().length === 0) {
    return 'English'
  }
  if (language.includes(('Philippine'))) {
    return 'Tagalog';
  }
  if (language.toLowerCase().includes(('sign language'))) {
    return 'Sign Language';
  }
  const formattedLanguage = _.capitalize(language.toLowerCase());
  if (!allowedLanguges.includes(formattedLanguage)) {
    alert(`Please add language ${formattedLanguage} for MRN ${mrn} as a dropdown option`);
  }
  return formattedLanguage;
}
export const getRxPrescribed = (rxPrescribed: string) => {
  const formattedData = rxPrescribed.toLowerCase().trim();
  if (formattedData === 'yes') {
    return 'Yes';
  }
  if (formattedData === 'no') {
    return 'No';
  }
  return 'Unknown';
};


export const getGender = (gender: null | string, mrn: string) => {
  if (gender == null || gender.trim().length === 0) {
    return 'Unknown';
  }
  if (gender.toLowerCase() === 'male') {
    return 'Male';
  }
  if (gender.toLowerCase() === 'female') {
    return 'Female';
  }
  if (gender.toLowerCase().includes('genderqueer')
    || gender.toLowerCase().includes('nonconforming')
    || gender.toLowerCase().includes('nonbinary')
    || gender.toLowerCase().includes('non-binary')
    || gender.toLowerCase().includes('non binary')
  ) {
    return 'Nonbinary';
  }
  if (gender.toLowerCase() === 'other') {
    return 'Other';
  }
  if (gender.toLowerCase() === 'transgender female') {
    return 'Transgender Male to Female';
  }
  if (gender.toLowerCase() === 'transgender male') {
    return 'Transgender Female to Male';
  }
  if (gender.toLowerCase() === 'transgender male') {
    return 'Transgender Female to Male';
  }
  if (gender.toLowerCase().includes('not to disclose')) {
    return 'Unknown';
  }
  if (gender.toLowerCase().includes('unknown') || gender.toLowerCase().includes('uknown') || gender.toLowerCase().includes('x')) {
    return 'Unknown';
  }
  alert(`Please add gender ${gender} as a dropdown option for MRN ${mrn}`);
  return gender;
};

export const getRace = (race: string, mrn: string) => {
  if (race != null && (
    race.toLowerCase().includes('asian') ||
    race.toLowerCase().includes('chinese') ||
    race.toLowerCase().includes('korean') ||
    race.toLowerCase().includes('japanese') ||
    race.toLowerCase().includes('filipino') ||
    race.toLowerCase().includes('vietnamese')
  )) {
    return 'Asian';
  }
  if (
    race != null &&
    (race.toLowerCase().includes('black') ||
      race.toLowerCase().includes('african american'))
  ) {
    return 'Black or African American';
  }
  if (race != null && race.toLowerCase().includes('white')) {
    return 'White';
  }
  if (
    race != null &&
    (race.toLowerCase().includes('hispanic') ||
      race.toLowerCase().includes('latin') ||
      race.toLowerCase().includes('mexican') ||
      race.toLowerCase().includes('chicano') ||
      race.toLowerCase().includes('puerto rican')
  )) {
    return 'Hispanic or Latino';
  }
  if (
    race != null &&
    (race.toLowerCase().includes('hawaiian') ||
      race.toLowerCase().includes('islander') ||
      race.toLowerCase().includes('american indian') ||
      race.toLowerCase().includes('alaska native') ||
      race.toLowerCase().includes('native american'))
  ) {
    return 'Native Hawaiian or Other Pacific Islander';
  }
  if (race != null && (
    race.toLowerCase().includes('other') || race.toLowerCase().includes('unknown') || race.toLowerCase().includes('refused')
  )) {
    return 'Other';
  }
  if (race.trim().length === 0 || race == null) {
    return '';
  }
  alert(`Please add race ${race} as a dropdown option for MRN ${mrn}`);
  return race;
};

export const getEthnicity = (ethnicity: string) => {
  if (ethnicity == null || ethnicity.trim().length === 0) {
    return 'Unknown';
  }
  if (ethnicity.toLowerCase().includes('non-hispanic')
    || ethnicity.toLowerCase().includes('not hispanic')
    || ethnicity.toLowerCase().includes('non hispanic')) {
    return 'Not Hispanic';
  }
  if (ethnicity.toLowerCase().includes('hispanic')
    || ethnicity.toLowerCase().includes('latino')) {
    return 'Hispanic';
  }
  if (ethnicity.toLowerCase().includes('unknown')
    || ethnicity.toLowerCase().includes('refused')) {
    return 'Unknown';
  }
  alert(`Please add ethnicity ${ethnicity} as a dropdown option`);
  return ethnicity;
}
