import _ from "lodash";
import swal from "sweetalert2";
import { SiteTypesT, SymptoData } from "../types";
import { NavigatorSchemaT } from "../navAssignment/navOptions";
import { generateColumns } from './groupColumns';
import { UnassignedMergedDataT } from './mergeDuplicates';
import { assignPatientToNav } from './assignPatientToNav';
import { generateMessageData } from './introMessage';

export type MergedDataT = {
  data: SymptoData,
  warnings: null | string[],
};

const calculateNavAssignments = (navAssignments: NavigatorSchemaT[]) => ({
  remainingPatients: _.sum(navAssignments.map((nav) => (
    (nav.adminCount ?? 0) + (nav.referralCount ?? 0)
  ))),
  remainingSpanishQuota: _.sum(navAssignments.map((nav) => (
    nav.prioritizeSpanish ? (nav.adminCount ?? 0) + (nav.referralCount ?? 0) : 0
  ))),
});

const hydrateServiceIntroPatients = (
  patient: UnassignedMergedDataT,
): MergedDataT => {
  const basePatientData = {
    ...patient.data,
    Identification: ('Service Intro No Response' as SymptoData['Identification']),
    'No Service Introduction': ('FALSE' as 'TRUE' | 'FALSE'),
    'Navigator Name': '',
    'Secondary Navigator Name': ''
  };
  return {
    data: {
      ...basePatientData,
      'Intro Message': generateMessageData(basePatientData),
    },
    warnings: patient.warnings,
  }
};

const hasSpanishPatients = (navAssignments: NavigatorSchemaT[], targetSites: SiteTypesT[]) => {
  const spanishNavs = navAssignments
    .filter(({ sites, prioritizeSpanish }) => (
      sites.some((site) => targetSites.includes(site)) && prioritizeSpanish
    ));
  return {
    referralCount: _.sum(spanishNavs.map(({ referralCount }) => referralCount ?? 0)),
    adminCount: _.sum(spanishNavs.map(({ adminCount }) => adminCount ?? 0)),
  }
};


export const mergeData = (
  data: UnassignedMergedDataT[],
  navAssignments: NavigatorSchemaT[],
): MergedDataT[] => {
  const managedNavAssignments = _.keyBy(_.cloneDeep(navAssignments), 'navigatorName');
  const patientListManager = generateColumns(data);
  const finalList: {
    data: SymptoData;
    warnings: null | string[];
  }[] = [];
  const assignPatients = (prevRemainingPatients: number) => {
    Object.keys(managedNavAssignments).forEach((navName) => {
      const data = assignPatientToNav(
        patientListManager,
        hasSpanishPatients(Object.values(managedNavAssignments), managedNavAssignments[navName].sites),
        managedNavAssignments[navName],
      );
      if (data != null) {
        finalList.push(data.patient);
        managedNavAssignments[navName].adminCount = data.updatedNavigatorData.adminCount;
        managedNavAssignments[navName].referralCount = data.updatedNavigatorData.referralCount;
      }
    });
    const newRemainingPatients = calculateNavAssignments(Object.values(managedNavAssignments)).remainingPatients;
    if (newRemainingPatients === prevRemainingPatients) {
      return;
    }
    assignPatients(newRemainingPatients);
  }
  assignPatients(calculateNavAssignments(Object.values(managedNavAssignments)).remainingPatients);
  const unassignedReferrals = patientListManager.hasUnassignedReferrals();
  if (unassignedReferrals.length > 0) {
    swal.fire({
      title: 'There are unassigned referrals. Please assign them to a navigator.',
      text: `${JSON.stringify(unassignedReferrals, null, 2)}`,
      icon: 'warning',
    });
  }
  const unassignedCHFPatients = patientListManager.hasUnassignedCHFPatients();
  if (unassignedCHFPatients.length > 0) {
    swal.fire({
      title: 'There are unassigned CHF patients. Please assign them to a navigator.',
      text: `${JSON.stringify(unassignedCHFPatients, null, 2)}`,
      icon: 'warning',
    });
  }
  const unassignedAdminPatients = patientListManager.getRemainingAdminPatients();
  return [
    ...finalList,
    ...unassignedAdminPatients.map(hydrateServiceIntroPatients),
  ];
};
