import React from 'react'
import _ from 'lodash';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import UploadPane from './UploadPane';
import { type SheetTypeT, getMissingColumns } from './utils';
import { SymptoData } from '../types';
import { formatCSV } from '../csvParsing/formatCSV';

export type FileUploadDataT = {
  errors:  {
    errors: string[];
    data: Record<string, string>;
  }[],
  fileName: string,
  id: string,
  successes: {
    data: Omit<SymptoData, 'Intro Message' | 'Full Site Name' | 'Payor Category' | 'Secondary Navigator Name'>;
    errors: string[];
  }[],
  dataType: SheetTypeT,
  missingColumns: null | string[],
};

const FileUploadItem = ({
  onUpload,
  filesUploading,
  setFilesUploading,
}: {
  onUpload: (data: FileUploadDataT) => void,
  filesUploading: number,
  setFilesUploading: (func: (num: number) => number) => void,
}) => {
  return (
    <div className="px-3 w-100">
      <UploadPane
        filesUploading={filesUploading}
        setFilesUploading={setFilesUploading}
        onUpload={async ({
          records, fileName,
        }) => {
          if (records == null) {
            return;
          }
          const {
            missingColumns,
            detectedType,
          } = getMissingColumns(records);
          if (detectedType == null) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Failed to detect file type. Please check the file format',
            })
            return;
          }
          type FormattedData = {
            formattedData: {
                type: "error";
                error: string;
            } | {
                type: "ok";
                data: Omit<SymptoData, "Intro Message" | "Full Site Name" | "Payor Category" | 'Secondary Navigator Name'>;
                warnings: string[];
            },
            data: Record<string, string>
          }[];
          const formattedCSV = await _.chunk(records, 20)
            .reduce(async (acc, chunk) => {
              const data = await acc;
              await Promise.all(chunk.map(async (currentRow) => {
                const formattedData = await formatCSV(currentRow, detectedType);
                data.push({
                  formattedData,
                  data: currentRow,
                });
              }));
              return data;
            }, Promise.resolve(([] as  FormattedData)));
          onUpload({
            dataType: detectedType,
            missingColumns,
            fileName,
            errors: _.compact(formattedCSV.map(({ formattedData, data }) => {
              return formattedData.type === 'error' ? {
                errors: [formattedData.error],
                data,
              } : null;
            })),
            id: uuidv4(),
            successes: _.compact(formattedCSV.map(({ formattedData }) => {
              return formattedData.type === 'ok' ? {
                data: formattedData.data,
                errors: formattedData.warnings || [],
              } : null;
            })),
          });

        }}
      />
    </div>
  );
};

export default FileUploadItem;
