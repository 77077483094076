import React, { useMemo } from 'react'
import _ from 'lodash';
import { Table, Button, Modal } from 'react-bootstrap';
import ItemPreview from './ItemPreview';
import './index.scss';

const PreviewModal = ({
  csvData,
  title,
  onClose,
  showErrorReasonsSummary,
}: {
  csvData: Array<{
    data: Record<string, string>,
    errors: null | string[],
  }>,
  title: string,
  showErrorReasonsSummary: boolean,
  onClose: () => void,
}) => {
  const tableHeaders = useMemo(() => {
    const headers = new Set<string>();
    csvData.forEach((data) => {
      Object.keys(data.data).forEach((key) => headers.add(key));
    });
    return Array.from(headers);
  }, [csvData]);
  return (
    <Modal className="PreviewModal" show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <div className="font-weight-light">{title}</div>
      </Modal.Header>
      <Modal.Body className="d-flex  flex-column align-items-start bg-light">
        {
          showErrorReasonsSummary && (
            <div className="bg-white d-block border p-3  text-small m-3">
              <div className="font-weight-bold text-danger">Error Reasons Summary</div>
              <div className="text-small">
                {
                  _.uniq(_.flatten(csvData.map(({ errors }) => errors)))
                    .map((error) => (
                      <div key={error}>
                        {error}
                      </div>
                    ))
                }
              </div>
            </div>
          )
        }
        <Table style={{ fontSize: '0.9em' }} className="p-3 border bg-white m-3">
          <thead>
            <tr>
              {tableHeaders.map((row) => (
                <th style={{ width: '20em' }} className="p-3" key={row}>
                  {row}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {csvData.map((data, index) => (
              <ItemPreview
                key={index}
                headers={tableHeaders}
                csvData={data}
              />
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-column align-items-start">
        <Button variant="primary" className="text-white text-small" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PreviewModal;
