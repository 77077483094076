import React from 'react'
import { Form, Button } from 'react-bootstrap';
import { MessageBuilder } from 'slack-block-builder';
import Swal from 'sweetalert2';
import axios from 'axios';
import CardItem from './utils/CardItem';

export const sendToSlack = async (slackBlocks: MessageBuilder): Promise<boolean> => {
  const currentSlackToken = localStorage.getItem('slackToken');
  const hasSlackNotifs = localStorage.getItem('slackNotifs') === 'true';
  if (!hasSlackNotifs) {
    return true;
  }
  const { text, blocks } = slackBlocks.buildToObject()
  const body = `blocks=${JSON.stringify(blocks)}&channel=C04PJHJTZSR&text=${text}&token=${currentSlackToken}`

  const { data } = await axios.post('https://slack.com/api/chat.postMessage', body, {
    timeout: 10000,
    transformRequest(data, headers) {
      delete headers['Content-Type'];
      return data;
    }
  });
  if (!data.ok) {
    await Swal.fire({
      title: 'Error',
      text: 'Error sending message to Slack',
      icon: 'error',
    });
    return false;
  } else {
    await Swal.fire({
      title: 'Success',
      text: 'Message sent to Slack',
      icon: 'success',
    });
    return true;
  }
};

const SlackMessage = () => {
  const hasSlackToken = localStorage.getItem('slackToken') != null &&
    String(localStorage.getItem('slackToken')).length > 0

  return (
    <CardItem
      className="bg-white mx-5 mt-5"
      title="Set Slack Token"
    >
      <Form.Check
        type="switch"
        label="Enable Slack Notifications"
        className="text-small"
        checked={localStorage.getItem('slackNotifs') != null && localStorage.getItem('slackNotifs') === 'true'}
        onChange={(e) => {
          localStorage.setItem('slackNotifs', String(e.target.checked));
          window.location.reload();
        }}
      />
      <div className="d-flex align-items-center">
        <div className="font-weight-bold text-small">
          {hasSlackToken != null ? (
            <div className="text-success">
              Slack token set
            </div>
          ) : (
            <div className="text-danger">
              Slack token not set
            </div>
          )}
        </div>
        <Button
          onClick={async () => {
            const { value: updatedSlackToken } = await Swal.fire({
              title: 'Slack Token',
              input: 'text',
              inputLabel: 'Slack Token',
              inputPlaceholder: 'Enter slack token',
              ...(hasSlackToken
                ? {
                  inputValue: String(localStorage.getItem('slackToken')),
                }
                : {}),
            });
            localStorage.setItem('slackToken', updatedSlackToken);
          }}
          className="text-small font-weight-bold text-decoration-none px-2"
          variant="link"
        >
          Edit
        </Button>
      </div>
    </CardItem>
  );
};

export default SlackMessage;
