import React, { useState } from 'react';
import _ from 'lodash';
import { Button, Form } from 'react-bootstrap';
import { Message, Blocks } from 'slack-block-builder';
import { filterExistingEncounters } from './skippedPatients';
import CardItem from './utils/CardItem';
import { type SymptoData } from "./types";
import { sendToSlack } from './SlackMessage';

const ExistingEncounters = ({
  rawPatientData,
  onParse,
}: {
  rawPatientData: Omit<SymptoData, 'Intro Message' | 'Full Site Name' | 'Payor Category' | 'Secondary Navigator Name' >[],
  onParse: (data: Omit<SymptoData, 'Intro Message' | 'Full Site Name' | 'Payor Category' | 'Secondary Navigator Name'>[]) => void,
}) => {
  const [auth, setAuth] = useState<{
    username: string,
    password: string,
  }>({
    username: '', password: '',
  });
  const [numUploads, setNumUploads] = useState(0);
  const [loading, setLoading] = useState(false);
  const [numSkipped, setNumSkipped] = useState<null | number>(null);
  return (
    <CardItem
      className="bg-white mx-5 mt-5"
      title="Identify Existing Encounters in Sympto"
    >
      <>
        {numSkipped == null && (
          <Form.Group>
            <Form.Label className="text-secondary font-weight-light">
              Email
            </Form.Label>
            <Form.Control
              type="text"
              value={auth.username}
              onChange={(e) => {
                setAuth({
                  ...auth,
                  username: e.target.value,
                });
              }}
            />
            <Form.Label className="text-secondary font-weight-light">
              Password
            </Form.Label>
            <Form.Control
              type="password"
              value={auth.password}
              onChange={(e) => {
                setAuth({
                  ...auth,
                  password: e.target.value,
                });
              }}
            />
            <Button
              variant="primary"
              disabled={loading}
              className="mt-3 d-block mx-auto text-white"
              onClick={async () => {
                setLoading(true);
                setNumUploads(0);
                try {
                  const filteredEncounters = await filterExistingEncounters(
                    _.uniqWith(rawPatientData, _.isEqual),
                    auth,
                    () => {
                      setNumUploads((curNumUploads) => (curNumUploads + 1));
                    },
                  )
                  setLoading(false);
                  const numSkippedBecauseofDuplicateEncounters = filteredEncounters.filter((encounter) => (
                    encounter.encounterMetadata.encounterAlreadyExists && !encounter.encounterMetadata.hasServiceIntroInPast30Days
                  )).length;
                  const numSkippedBecauseofServiceIntro30Days = filteredEncounters.filter((encounter) =>
                    encounter.encounterMetadata.hasServiceIntroInPast30Days && !encounter.encounterMetadata.encounterAlreadyExists
                  ).length;
                  const numSkippedBoth = filteredEncounters.filter((encounter) =>
                  encounter.encounterMetadata.hasServiceIntroInPast30Days && encounter.encounterMetadata.encounterAlreadyExists
                ).length;
                  const validEncounters = filteredEncounters.filter((encounter) => !encounter.encounterMetadata.encounterAlreadyExists && !encounter.encounterMetadata.hasServiceIntroInPast30Days)
                    .map(({ patientData }) => patientData);
                  if (await sendToSlack(Message({
                    text: `# of encounters skipped`,
                  }).blocks(
                    Blocks.Section({
                      text: `*${numSkippedBecauseofDuplicateEncounters} of ${rawPatientData.length}* encounters have existing encounters and will not be included in the upload`,
                    }),
                    Blocks.Section({
                      text: `*${numSkippedBecauseofServiceIntro30Days} of ${rawPatientData.length}* encounters are being skipped b/c had service intro within 30 days`,
                    }),
                    Blocks.Section({
                      text: `*${numSkippedBoth} of ${rawPatientData.length}* encounters are skipped because of both of the above reasons (the numbers above don't overlap)`,
                    }),
                      Blocks.Section({
                      text: `*${validEncounters.length} of ${rawPatientData.length}* encounters being included in the upload`,
                    }),
                  ))) {
                    setNumSkipped(numSkipped);
                    onParse(
                      validEncounters
                    )
                  } else {
                    setNumUploads(0);
                  }
                } catch (e) {
                  setLoading(false);
                  setNumUploads(0);
                }
              }}
            >
              Identify Duplicate Encounters
              <div className="text-small">
                {numUploads !== 0
                  ? ` (${numUploads}/${rawPatientData.length}) Records Complete`
                  : ''}
              </div>
            </Button>
          </Form.Group>
        )}
        {numSkipped != null && (
          <div className="text-muted text-large text-center p-4">
            {numSkipped} of {rawPatientData.length} encounters have existing encounters and will not be included in navigator assignment
          </div>
        )}
      </>
    </CardItem>
  )
}

export default ExistingEncounters;
