import _ from 'lodash';
import { PriorityLevelT, SymptoData } from '../types';
import { getPayorCategory } from './genericUtils';

export const REASONS = {
  NOPCP: 'No PCP - needs to establish',
  PCPFOLLOWUP: 'PCP follow-up',
  SPECIALIST: 'Specialist referral (please indicate in comments)',
  HIGH_UTILIZER: 'High Utilizer', // 2+ ED visits
  INSURANCE_TYPE: 'Insurance Type', // self pay, uninsured, medicaid,
  OTHER: 'Other (Please specify in comments)',
  CHF_PILOT: 'CHF Pilot',
};

// Define the list of unique CHF related diagnoses
const CHFDiagnoses = [
  "acute combined systolic (congestive) and diastolic (congestive) heart failure",
  "acute coronary syndrome",
  "acute diastolic (congestive) heart failure",
  "acute exacerbation of congestive heart failure",
  "acute on chronic combined systolic (congestive) and diastolic (congestive) heart failure",
  "acute on chronic congestive heart failure",
  "acute on chronic diastolic (congestive) heart failure",
  "acute on chronic systolic (congestive) heart failure",
  "acute systolic (congestive) heart failure",
  "arrhythmias",
  "biventricular heart failure",
  "cardio-respiratory failure & shock",
  "chf",
  "chronic atherosclerosis",
  "chronic combined systolic (congestive) and diastolic (congestive) heart failure",
  "chronic congestive heart failure",
  "chronic congestive heart failure (chf)",
  "chronic congestive heart failure, unspecified",
  "chronic diastolic (congestive) heart failure",
  "chronic right sided congestive heart failure (chf)",
  "chronic right-sided congestive heart failure",
  "chronic systolic (congestive) heart failure",
  "combined systolic (congestive) and diastolic (congestive) heart failure, unspecified",
  "congenital cardiac/circulatory defect",
  "congestive heart failure",
  "congestive heart failure (chf)",
  "congestive heart failure (chf) exacerbation (flareup)",
  "congestive heart failure (chf) following cardiac surgery",
  "congestive heart failure (chf) following noncardiac surgery",
  "congestive heart failure (chf) with cardiomyopathy",
  "congestive heart failure (chf), acute",
  "congestive heart failure (chf), chronic",
  "congestive heart failure (chf), chronic with acute flare up",
  "congestive heart failure as post-operative complication of cardiac surgery",
  "congestive heart failure as post-operative complication of non-cardiac surgery",
  "congestive heart failure as postoperative complication of cardiac surgery",
  "congestive heart failure as postoperative complication of non cardiac surgery",
  "congestive heart failure due to cardiomyopathy",
  "congestive heart failure due to valvular disease",
  "congestive heart failure stage b",
  "congestive heart failure stage b due to ischemic cardiomyopathy",
  "congestive heart failure stage c",
  "congestive heart failure stage c due to ischemic cardiomyopathy",
  "congestive heart failure stage d",
  "congestive heart failure stage d due to ischemic cardiomyopathy",
  "congestive heart failure with right heart failure",
  "congestive heart failure with unknown left ventricular ejection fraction",
  "congestive heart failure, chronic with acute flare up",
  "congestive left heart failure with right heart failure",
  "congestive rheumatic heart failure",
  "end stage heart failure",
  "exacerbation of congestive heart failure",
  "heart failure",
  "heart failure due to hypertension",
  "heart failure left side with ejection fraction 31-40%",
  "heart failure with left ventricular ejection fraction 41-49 percent",
  "heart failure with left ventricular ejection fraction greater than or equal to 50 percent",
  "heart failure with preserved ejection fraction",
  "heart failure with reduced ejection fraction",
  "heart infection/inflammation, except rheumatic",
  "hf",
  "high output heart failure",
  "hypertensive heart disease",
  "other and unspecified heart disease",
  "pneumonia",
  "right heart failure secondary to left heart failure",
  "unspecified diastolic (congestive) heart failure",
  "unspecified systolic (congestive) heart failure",
  "valvular and rheumatic heart disease",
  "vascular and circulatory disease"
];

export const TARGET_INSURANCE_CATEGORIES: Array<SymptoData['Payor Category']> = (
  ['Uninsured / Self-Pay', 'Medicaid', 'Managed Care Plan']
);


const isCHFPatient = ({
  site, dischargeDiagnoses, chiefComplaint,
}: {
  site: SymptoData['Sites'],
  dischargeDiagnoses: SymptoData['Discharge Diagnosis'],
  chiefComplaint: SymptoData['Chief Complaint'],
}) => {
  const eligibleSites = ['I22', 'I23'];
  const hasCHFDiagnosis = CHFDiagnoses.some(diagnosis =>
    dischargeDiagnoses.includes(diagnosis) || chiefComplaint.includes(diagnosis)
  );

  if (eligibleSites.includes(site) && hasCHFDiagnosis) {
    return {
      reason: REASONS.CHF_PILOT,
      additionalEncounterDetailComments: `Patient has CHF diagnosis. Please navigate for CHF Pilot.`,
    };
  }
  return null;
};



export const generateNavReasonsForMicrostrategy = ({
  insuranceType,
  numEDVisits6Months,
  dischargeDiagnoses,
  chiefComplaint,
  site,
}: {
  insuranceType: string,
  numEDVisits6Months: number,
  site: SymptoData['Sites'],
  dischargeDiagnoses: SymptoData['Discharge Diagnosis'],
  chiefComplaint: SymptoData['Chief Complaint'],
}) => ([
  isCHFPatient({
    dischargeDiagnoses,
    chiefComplaint,
    site,
  }) != null ? REASONS.CHF_PILOT : null,
  (numEDVisits6Months > 2 && numEDVisits6Months < 7) ? REASONS.HIGH_UTILIZER : null,
  TARGET_INSURANCE_CATEGORIES.includes(getPayorCategory(insuranceType))
    ? REASONS.INSURANCE_TYPE
    : null,
])

export const generateNavReasons = ({
  parsedEncounters, insuranceType, numEDVisits, dischargeDiagnoses, chiefComplaint, site,
}: {
  parsedEncounters: string,
  insuranceType: string,
  numEDVisits: number,
  site: SymptoData['Sites'],
  dischargeDiagnoses: SymptoData['Discharge Diagnosis'],
  chiefComplaint: SymptoData['Chief Complaint'],
}): {
  priority: PriorityLevelT,
  navReasons: string[],
  additionalEncounterDetailComments: string,
} => {
  const formattedEncounter = parsedEncounters.toLowerCase();

  const pcpValue = () => {
    const pcpPhrases = ['pcp', 'pmd', 'primary care', 'pediatrician', 'primary', 'pediatrics', 'prmary care'];
    const noPhrase = ['no', 'need', 'needs'];
    const phrases = _.flatten(noPhrase.map((noPrefix) => (
      pcpPhrases.map((pcpSuffix) => `${noPrefix} ${pcpSuffix}`)
    )));

    if (phrases.some((phrase) => formattedEncounter.includes(phrase))) {
      return {
        reason: REASONS.NOPCP,
        additionalEncounterDetailComments: null,
      };
    }
    if (pcpPhrases.some((pcpPhrase) => formattedEncounter.includes(pcpPhrase))) {
      return {
        additionalEncounterDetailComments: null,
        reason: REASONS.PCPFOLLOWUP,
      };
    }
    if (formattedEncounter.trim().length === 0) {
      return {
        reason: REASONS.OTHER,
        additionalEncounterDetailComments: 'Provider referred patient but did not select reason for referral, please review EMR for follow up notes-  navigate for PCP FOLLOW UP of none available.',
      };
    }
    return null;
  };

  const specialistValue = () => {
    const specialistPhrases = [
      'specialist', 'cardiology', 'gi', 'urology', 'pulmonology', 'urologist', 'orthopedic', 'ortho', 'ophthalmologist', 'dental', 'gyn', 'neurologist', 'surgery', 'fracture', 'fx',
      'podiatrist', 'hand doc', 'neuro', 'ent', 'podiatry', 'psych', 'gen surg', 'ob', 'general surgeon', 'opthamology', 'westwood', 'tia', 'opthamology',
      'wound care', 'rheumatology', 'hand surgeon'
    ];
    if (specialistPhrases.some((phrase) => formattedEncounter.includes(phrase))) {
      return {
        reason: REASONS.SPECIALIST,
        additionalEncounterDetailComments: null,
      };
    }
    return null;
  };

  const navReasons = _.compact([
    pcpValue(),
    specialistValue(),
    isCHFPatient({
      dischargeDiagnoses,
      chiefComplaint,
      site,
    }),
    (numEDVisits > 2 && numEDVisits < 7) ? {
      reason: REASONS.HIGH_UTILIZER,
      additionalEncounterDetailComments: null,
    }: null,
    TARGET_INSURANCE_CATEGORIES.includes(getPayorCategory(insuranceType)) ? {
      reason: REASONS.INSURANCE_TYPE,
      additionalEncounterDetailComments: null,
    } : null,
  ]);

  const navReasonList: string[] = navReasons.map(({ reason }) => reason);

  const priority = ((): PriorityLevelT => {
    // if its days, its always high without fail
    if (formattedEncounter.includes('asap') || formattedEncounter.includes('days') || formattedEncounter.includes('day')) {
      return '3) High';
    }

    // if its specialist, always high
    if (navReasonList.includes(REASONS.SPECIALIST)) {
      return '3) High';
    }

    // pcp follow up --> low if not days
    if (navReasonList.includes(REASONS.PCPFOLLOWUP)) {
      return '1) Low';
    }

    // weeks --> medium if not pcp followu p
    if (formattedEncounter.includes('weeks') || formattedEncounter.includes('week')) {
      // if pcp and more than a week, then its low priority
      return '3) High';
    }


    // medium if no pcp
    if (navReasonList.includes(REASONS.NOPCP)) {
      return '2) Medium';
    }

    return '1) Low';
  })();
  return {
    priority,
    navReasons: navReasonList,
    additionalEncounterDetailComments: _.compact(navReasons.map(({ additionalEncounterDetailComments }) => additionalEncounterDetailComments)).join('\n'),
  }
};
