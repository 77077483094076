import React, { useState } from "react";
import _ from "lodash";
import { Button } from "react-bootstrap";
import useLocalStorage from "use-local-storage";
import FileUpload from "./FileUpload";
import { type SymptoData } from "./types";
import { mergeData, type MergedDataT } from "./mergedColumns/mergeData";
import NavAssignment from "./navAssignment";
import { DEFAULT_NAV_OPTIONS } from "./navAssignment/navOptions";
import NavigatorAnalytics from "./navigatorAnalytics";
import SlackMessage from "./SlackMessage";
import ExistingEncounters from "./ExistingEncounters";
import UploadStats from "./mergedColumns/UploadStats";
import { mergeDuplicates, UnassignedMergedDataT } from './mergedColumns/mergeDuplicates';
import "./App.scss";

function App() {
  const [flattenedRawData, setFlattenedRawData] = useState<null | Omit<SymptoData, 'Intro Message' | 'Full Site Name' | 'Payor Category' | 'Secondary Navigator Name'>[]>(null);

  const [mergedFilteredPatientData, setMergedFilteredPatientData] = useState<null | UnassignedMergedDataT[]>(null);

  const [navOptions, setNavOptions] = useLocalStorage('nav-options-7', DEFAULT_NAV_OPTIONS);

  const [mergedData, setMergedData] = useState<MergedDataT[]>([]);
  const [hasUpdates, setHasUpdates] = useState(true);
  return (
    <div className="bg-light pt-3">
      <SlackMessage />
      <FileUpload
        uploadFinished={flattenedRawData != null}
        onUpload={(updatedRawData) => {
          setFlattenedRawData(
            // get unique encounters
            _.uniqWith(
              _.flatten(updatedRawData.map((data) => data.successes.map(({ data: d }) => d))),
              _.isEqual,
            )
          );
        }}
      />
      {flattenedRawData && (
        <>
          <ExistingEncounters
            rawPatientData={flattenedRawData}
            onParse={(filteredPatientData) => {
              setMergedFilteredPatientData(mergeDuplicates(filteredPatientData));
            }}
          />
        </>
      )}
      {mergedFilteredPatientData && (
        <>
          <div className="d-flex">
            <UploadStats
              mergedData={mergedFilteredPatientData}
              assignedData={mergedData}
            />
            <NavAssignment
              navAssignments={navOptions}
              setNavAssignment={(newAssignments) => {
                setNavOptions(newAssignments);
                setHasUpdates(true);
              }}
              mergedData={mergedData}
            />
          </div>
         <div className="d-flex align-items-start justify-content-around py-3 px-5 w-100">
          <div>
            {mergedData && (
              <NavigatorAnalytics
                mergedData={mergedData}
                navigatorAssignments={navOptions}
              />
            )}
          </div>

        </div>
        {
          hasUpdates && (
            <Button
              onClick={() => {
                setMergedData(mergeData(
                  mergedFilteredPatientData,
                  navOptions,
                ));
                setHasUpdates(false);
              }}
              className="target-button text-white shadow"
            >
              Generate Updated CSV
            </Button>
          )
        }
        </>
      )}

    </div>
  );
}

export default App;
