import moment from 'moment';
import { SymptoData } from '../types';
import { extractDemographics } from './epicUtils';
import { getLanguage } from './genericUtils';
import { generateNavReasons } from './reasonsForNav';

export const formatCSVData = async (data: Record<string, string>): Promise<{
  type: 'error', error: string,
} | {
  type: 'ok', data: Omit<SymptoData, 'Intro Message' | 'Full Site Name' | 'Payor Category' | 'Secondary Navigator Name'>, warnings: Array<string>,
}> => {
  const warnings = [];
  const demographics = await extractDemographics(data);
  if (demographics.type === 'error') {
    return { type: 'error', error: demographics.error };
  }
  const { data: demographicsData, warning: demographicsWarning } = demographics;
  warnings.push(...(demographicsWarning || []));


  const parsedEDReferralDate = moment(data['Arr Date'], 'M/D/YYYY', true)
  if (!parsedEDReferralDate.isValid()) {
    warnings.push(`Invalid ED Arrival Date: ${data['Arr Date']}`);
  }

  const encounterDetails = [data['Question response'], data['Order Comments'] || ''].join(', ');
  const language = data['Language'] ? data['Language'].trim() : '';
  const isSpanishSpeaking = language.toUpperCase().includes('SPANISH') ? 'TRUE' : 'FALSE';
  warnings.push(`Patient language is ${language}. Is Spanish Speaking: ${isSpanishSpeaking}`);
  const { navReasons, priority } = generateNavReasons({
    parsedEncounters: encounterDetails || '',
    dischargeDiagnoses: demographicsData['Discharge Diagnosis'],
    chiefComplaint: demographicsData['Chief Complaint'],
    site: demographicsData.Sites,
    insuranceType: demographics.data['Payer Type'],
    numEDVisits: Number(data['ED Vis Last 90 Days'].trim().length > 0 ? (data['ED Vis Last 90 Days']) : 0),
  })

  return {
    type: 'ok',
    data: {
      'ED Visit/Referral Date': moment(data['Arr Date'], 'M/D/YYYY').format('MM/DD/YYYY'),
      'Provider / On-Site Coordinator Name': data['Ordering Provider'],
      "Patient's Primary Language": getLanguage(language, demographicsData.MRN),
      'Spanish Speaking': isSpanishSpeaking,
      'Reason for Navigation': navReasons.join(','),
      'Priority Level': priority,
      'Encounter Details/Comments': encounterDetails,
      'Navigator Name': '',
      'Visits within 6 mo\'s': String(data['ED Vis Last 90 Days'].trim().length > 0 ? (data['ED Vis Last 90 Days']) : 0),
      Identification: 'Provider Referral',
      'No Service Introduction': 'TRUE',

      ...demographicsData,
    },
    warnings,
  };
};
