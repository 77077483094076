import qs from 'qs';
import moment from 'moment';
import asyncRetry from 'async-retry';
import pLimit from 'p-limit';
import { symptoReq,  generateAuthCode } from './login';
import { SymptoData } from './types';

type BearerT = {
  authCode: string, rateToken: string,
};

const limit = pLimit(10);


const generateAuthHeader = (auth: BearerT) => ({
  'Authorization': 'Bearer '+auth.authCode,
});

// whether or not the patient already exists + whtehre an encounter also already exists
// with the same encounter number
const doesEncounterAlreadyExist = async (
  patientTvId: string, patientData: Omit<SymptoData, 'Intro Message' | 'Full Site Name' | 'Payor Category' | 'Secondary Navigator Name'>, authToken: BearerT,
): Promise<{ encounterAlreadyExists: boolean, hasServiceIntroInPast30Days: boolean }> => {
  const isServiceIntroUpload = patientData.Identification === 'Service Intro No Response';
  // find when last encounter created
  const params = qs.stringify({
    type: 'Date Filter',
    patientTvId,
    startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getTime()
  });
  const existingEncounters = (await symptoReq(`/providers/patientSurvey?${params}`, {
    method: 'GET',
  }, generateAuthHeader(authToken)) as {
    name: string,
    patientSurveyId: string,
    patientId: string,
    surveyTags: null | string[],
    type: 'instrument' | 'encounter',
    startDate: number,
    previewData: null | {
      Identification: string,
    }
  }[]);
  const  matchingEncounters = existingEncounters.filter(({ type, previewData, surveyTags }) => {
    if (type !== 'encounter') {
      return false;
    }
    // if not service intro upload, but identification of encounter is service intro, then
    // don't include as a matching encounter
    if (!isServiceIntroUpload && previewData != null && previewData.Identification === 'Service Intro No Response') {
      return false;
    }
    return surveyTags == null || !surveyTags.includes('Invalid / Accidental Encounter');
  });

  type MinimalPatientSurvey = {
    type: 'superscore',
    output: string,
    tags: string[],
    title: string,
    id: string,
  }[];
  const encounterData = await matchingEncounters.reduce(async (acc, encounter) => {
    return [
      ...(await acc),
      ((await symptoReq(
        `/providers/patientSurvey/${encounter.patientSurveyId}/minimal`,
        {
          method: 'GET',
        },
        generateAuthHeader(authToken),
      )) as MinimalPatientSurvey),
    ]
  }, Promise.resolve([] as MinimalPatientSurvey[]));
  const parsedEncounterData = encounterData.map((values) => {
    const navName = values.find(({ id }) => id === 'navigator_name');
    const updatedEncounterNo = values.find(({ id }) => id === 'updatedEncounterNo');
    const existingEncounterNo = values.find(({ id }) => id === 'existingEncounterNo');
    return {
      currentNavigatorName: navName != null && navName.output.trim().length > 0
        ? navName.output
        : null,
      encounterNo: existingEncounterNo != null
        ? existingEncounterNo.output
         :null,
      updatedEncounterNo: updatedEncounterNo != null
        ? updatedEncounterNo.output
        : null,
    }
  }).filter(({ encounterNo, updatedEncounterNo }) => (
    encounterNo === patientData['Encounter Number']
    || updatedEncounterNo === patientData['Encounter Number']
  ));


  const hasServiceIntroInPast30Days = (() => {
    if (patientData.Identification === 'Service Intro No Response') {
      const latestEncounter = Math.max(...matchingEncounters.map(({ startDate }) => (
        startDate
      )));
      if (moment(latestEncounter).isAfter(moment().subtract(30, 'day'))) {
        return true;
      }
    }
    return false;
  })();


  return { encounterAlreadyExists: parsedEncounterData.length === 1, hasServiceIntroInPast30Days }
};

export const filterExistingEncounters = async (
  userData: Omit<SymptoData, 'Intro Message' | 'Full Site Name' | 'Payor Category' | 'Secondary Navigator Name'>[],
  auth: { username: string, password: string },
  finishPatient: () => void,
): Promise<{
  patientData: Omit<SymptoData, 'Intro Message' | 'Full Site Name' | 'Payor Category' | 'Secondary Navigator Name'>,
  encounterMetadata: {
    encounterAlreadyExists: boolean, hasServiceIntroInPast30Days: boolean
  },
}[]> => {
  const authCode: BearerT = await generateAuthCode(auth);

  const validPatients = await Promise.all(userData.map((patientData) => (limit(async ()=> {
    return asyncRetry(async () => {
      const targetMRN = patientData.MRN;
      const { patients } = (await symptoReq(`/providers/patients/cache?searchTerm=${targetMRN}&limit=100&offset=0`, {
        "body": null,
        "method": "GET",
      }, generateAuthHeader(authCode)) as {
        patients: {
          mrn: string,
          tvid: string,
        }[],
      });

      const matchingMRN = patients.find(({ mrn }) => mrn === targetMRN);
      const encounterMetadata = matchingMRN == null
        ? {
          encounterAlreadyExists: false,
          hasServiceIntroInPast30Days: false,
        }
        : await doesEncounterAlreadyExist(
          matchingMRN.tvid, patientData, authCode,
        );
      finishPatient();
      return {
        patientData,
        encounterMetadata,
      };
    });
  }))));
  return validPatients;
}

