import _ from 'lodash';
import moment from 'moment';
import { parseFullName } from 'parse-full-name';
import { type MMDDYYYYFormat, type SiteTypesT } from '../types';
import { validateAndFormatPhoneNumber } from './phoneValidate';
import { type ExtractRespT, getRace, getGender, getEthnicity } from './genericUtils';

const ALLOWED_SITES: Record<string, SiteTypesT> = {
  'SFHED': 'I16',
  'SMNED': 'I19',
  'RMCED': 'I17',
  'SJHED': 'I18',
  'SEHED': 'I19',
};

export const extractSite = (row: Record<string, string>): ExtractRespT<{
  Sites: SiteTypesT,
}> => {
  const siteName = row['ED'];
  if (siteName.trim().length === 0) {
    return { type: 'error', error: 'Site name is empty' };
  }
  const site = ALLOWED_SITES[siteName];
  if (site === undefined) {
    return { type: 'error', error: `Site name "${siteName}" is not allowed` };
  }
  return { type: 'ok', data: { Sites: site }, warning: null };
};


const extractPhoneNumber = (row: Record<string, string>): ExtractRespT<{
  'Phone Number': string,
}> => {
  // the difefrent phone number optiosn by priroity - see if any match
  const fieldData = (row['Phone'] || '').split('\n')
  const phoneNumberList = _.compact([
    fieldData.find((row) => row.includes('Mobile')),
    ...fieldData,
  ].map((phNo) => {
    const validatedPhoneNumber = phNo != null ? validateAndFormatPhoneNumber(phNo) : null;
    if (validatedPhoneNumber != null && validatedPhoneNumber.status === 'ok') {
      return validatedPhoneNumber.phoneNumber;
    }
    return null;
  }));
  const targetPhone = _.head(phoneNumberList);

  return {
    type: 'ok',
    data: { 'Phone Number': targetPhone || ''  },
    warning: phoneNumberList.length === 0 || targetPhone == null
      ? [`Invalid cell phone number. Invalid options ${fieldData.join(', ')}`]
      : [],
  }
}

const extractPCP = async (pcpWithPhone: string): Promise<{
  'PCP Provider Name': string,
  'PCP Phone': string,
  'Provider NPI': string,
  'Provider Specialty': string
  'Clinic Address': string
  'Clinic Name': string
  'PCP Medical Group': string
}> => {
  if (pcpWithPhone.includes('PCP UNKNOWN') || pcpWithPhone.includes('PCP NOT IN SYSTEM')) {
    return {
      'PCP Provider Name': pcpWithPhone,
      'PCP Phone': 'Unknown',
      'Provider NPI': 'Unknown',
      'Provider Specialty': 'Unknown',
      'Clinic Name': 'Unknown',
      'Clinic Address': 'Unknown',
      'PCP Medical Group': 'Unknown',
    }
  }
  if (pcpWithPhone === 'NO PRIMARY CARE, PHYSICIAN') {
    return {
      'PCP Provider Name': pcpWithPhone,
      'PCP Phone': 'None',
      'Provider NPI': 'None',
      'Provider Specialty': 'None',
      'Clinic Name': 'None',
      'Clinic Address': 'None',
      'PCP Medical Group': 'None',
    }
  }
  const { first, last } = parseFullName(pcpWithPhone);
  const [
    providerNameParsed, providerPhoneNumber,
  ] = pcpWithPhone.endsWith(')')
    ? [
        pcpWithPhone.split('(')[0].trim(),
        pcpWithPhone.split('(')[1].replace(')', '')
    ]
    : [pcpWithPhone, ''];

  if (first == null || last == null) {
    return {
      'PCP Provider Name': providerNameParsed,
      'PCP Phone': providerPhoneNumber,
      'Provider NPI': '',
      'Provider Specialty': '',
      'Clinic Name': '',
      'Clinic Address': '',
      'PCP Medical Group': '',
    }
  }
  try {
    const resp = (await (await fetch(
      `https://tsc-node.fly.dev/npi?firstName=${first}&lastName=${last}`,
      {
        "body": null,
        "headers": {
          "accept": "*/*",
          "accept-language": "en-US,en;q=0.9",
        },
        "method": "GET",
        "mode": "cors",
      }
    )).json() as {
      results: {
        number: string,
        addresses: {
          address_purpose: 'LOCATION' | string,
          address_1: string,
          city: string,
          state: string,
          postal_code: string,
          telephone_number: string,
        }[],
        basic: {
          first_name: string
          last_name: string
          middle_name: string
        },
        taxonomies: {
          desc: string
        }[],
      }[]
    });
    if (resp.results.length > 1) {
      console.warn('More than one result for', pcpWithPhone, resp.results);
    }
    return {
      'PCP Provider Name': providerNameParsed,
      'PCP Phone': resp.results[0].addresses[0].telephone_number,
      'Provider NPI': resp.results[0].number,
      'Provider Specialty': resp.results[0].taxonomies[0].desc,
      'Clinic Name': '',
      'Clinic Address': `${resp.results[0].addresses[0].address_1}, ${resp.results[0].addresses[0].city}, ${resp.results[0].addresses[0].state} ${resp.results[0].addresses[0].postal_code}}`,
      'PCP Medical Group': '',
    }
  } catch (e) {
    return {
      'PCP Provider Name': providerNameParsed,
      'PCP Phone': providerPhoneNumber,
      'Provider NPI': '',
      'Provider Specialty': '',
      'Clinic Name': '',
      'Clinic Address': '',
      'PCP Medical Group': '',
    }
  }
}

export const extractDemographics = async (row: Record<string, string>): Promise<ExtractRespT<{
  'First Name': string,
  'Last Name': string,
  MRN: string,
  'Date of Birth': MMDDYYYYFormat,
  'Encounter Number': string,
  Sites: SiteTypesT,
  'Phone Number': string,
  'Alt Phone #': string,
  'Email Address': string,
  Sex: string,
  Race: string,
  Gender: string,
  'Is Pregnant?': 'Yes' | 'No' | 'Unknown',
  Address: string,
  'Discharge Diagnosis': string,
  'Chief Complaint': string,
  'PCP Provider Name': string,
  'PCP Phone': string,
  'Provider NPI': string,
  'PCP Medical Group': string,
  'Provider Specialty': string
  'Clinic Name': string
  'Clinic Address': string
  Ethnicity: string,
  'Emerg Contact': string,
  'Emerg Contact Phone': string,
  'ED Visit Summary': string,
  Prescriptions: string,
  Referrals: string,
  'Rx Prescribed': string,
  InsuranceID: string,
  'Payer Type': string,
  'Secondary Payer Type': string
  'Secondary InsuranceID': string,
}>> => {
  const firstName = _.capitalize(row['First Name']);
  const warnings = [];
  if (firstName.trim().length === 0) {
    warnings.push('First name is empty');
  }
  const lastName = _.capitalize(row['Last Name']);
  if (lastName.trim().length === 0) {
    warnings.push('Last name is empty');
  }
  const dob = row['Date of Birth'];
  const formattedDOB = moment(dob, 'M/D/YYYY', true);
  if (!formattedDOB.isValid()) {
    return { type: 'error', error: `Date of birth "${dob}" is invalid` };
  }
  const mrn = row['MRN'];
  if (mrn == null || mrn.trim().length === 0) {
    return { type: 'error', error: 'MRN is empty' };
  }
  const encounterNumber = row['Hospital Account ID'];
  if (encounterNumber.trim().length === 0) {
    return { type: 'error', error: 'Encounter number is empty' };
  }

  const siteResp = extractSite(row);
  if (siteResp.type === 'error') {
    return siteResp;
  }
  if (siteResp.warning !== null) {
    warnings.push(...siteResp.warning);
  }

  const phoneNumberResp = extractPhoneNumber(row);
  if (phoneNumberResp.type === 'error') {
    return phoneNumberResp;
  }
  if (phoneNumberResp.warning !== null) {
    warnings.push(...phoneNumberResp.warning);
  }
  const rawGender = row['Gender'] != null ? row['Gender'] : '';
  const processedGender = getGender(rawGender, mrn);
  const diagnosis = row['Primary ED Dx'] ? row['Primary ED Dx'].split(',') : [];
  const diagnoses = row['ED Dx'] ? row['ED Dx'].split(',') : [];
  const dischargeDiagnosis = _.uniq([...diagnosis, ...diagnoses]).join(', ');
  const pregnancyStatus = (() => {
    if (row['Is Pregnant?'] === 'Yes') {
      return 'Yes';
    }
    if (row['Is Pregnant?'] === 'No') {
      return 'No';
    }
    warnings.push(`Pregnancy status is "${row['Is Pregnant']} - marked as unknown"`);
    return 'Unknown';

  })();
  return {
    type: 'ok',
    data: {
      MRN: mrn,
      'First Name': firstName,
      'Phone Number': phoneNumberResp.data['Phone Number'],
      'Alt Phone #': '-',
      'Email Address': row['Pt. E-mail Address'],
      Sites: siteResp.data.Sites,
      'Last Name': lastName,
      'Encounter Number': encounterNumber,
      'Date of Birth': formattedDOB.format('MM/DD/YYYY'),
      Address: row['Address'].replaceAll('\n', ' ').replaceAll('\r', ''),
      Sex: row['Sex'] != null ? row['Sex'] : '',
      Gender: processedGender,
      Race: getRace(row['Race'] != null ? row['Race'].replaceAll('\n', ' ').replaceAll('\r', '') : '', mrn),
      'Is Pregnant?': pregnancyStatus,
      'Discharge Diagnosis': dischargeDiagnosis,
      'Chief Complaint': row['CC'],
      ...(await extractPCP(row['PCP w/ Phone'])),
      Ethnicity: getEthnicity(row['Ethnicity'] != null
        ? row['Ethnicity']
        .replaceAll(' [1]', '')
        .replaceAll(' [3]', '')
        .replaceAll(' [2]', '')
        .trim()
      : ''),
      'Payer Type': (row['Payor'] || '').trim().length !== 0
        ? row['Payor']
        : row['Fin Class'],
      InsuranceID: row['Primary Mem ID'],

      'Emerg Contact': row['Emerg Contact'],
      'Emerg Contact Phone': row['Emerg Contact Ph'],

      'Rx Prescribed': row['Medications'] != null && row['Medications'].trim().length > 0 && row['Medications'].toLowerCase() !== 'none'
        ? 'Yes'
        : 'No',
      'Prescriptions': row['Medications'],

      'Secondary Payer Type': row['Secondary Payor'],
      'Secondary InsuranceID': row['Secondary Mem ID'],

      'ED Visit Summary': '',
      Referrals: '',
    },
    warning: warnings.length > 0 ? warnings : null,
  };
};
