import React, {  useState } from 'react'
import _ from 'lodash';
import cx from 'classnames';
import { Button } from 'react-bootstrap';
import { FileUploadDataT } from './FileUploadItem';
import { extractSite as extractEpicSite } from '../csvParsing/epicUtils';
import { extractSite as extractMeditechSite } from '../csvParsing/meditechUtils';
import { INVALID_ED_DISPOSITION } from '../csvParsing/genericUtils';

import PreviewModal from '../PreviewModal';

const TITLE_MAPPING = {
  'EPIC-Microstrategy': 'EPIC Microstrategy',
  'EPIC-Referrals': 'EPIC Referrals',
  'Meditech-Microstrategy': 'Meditech Microstrategy',
  'Meditech-Referrals': 'Meditech Referrals',
};

export const fetchErrors = (currentData: FileUploadDataT, includeEDDispositionErrors: boolean) => {
  const formattedErrors = _.uniq(_.flatten(
    currentData.errors.map((e) => e.errors)
  ).map((e) => e.includes('Invalid cell phone number') ? 'Invalid cell phone number' : e))
    .filter((e) => !e.startsWith(INVALID_ED_DISPOSITION) || includeEDDispositionErrors);
  const patientsWithError = formattedErrors.map((errorMessage) => {
    // find all patients with this error
    const patients = currentData.errors.filter((e) => e.errors.some((curErr) => curErr.startsWith(errorMessage)))
      .map(({ data }) => data);
    const siteCount = patients.reduce((acc: { [siteCode: string]: number }, cur) => {
      const siteItem = (currentData.dataType).includes('Meditech')
        ? extractMeditechSite(cur)
        : extractEpicSite(cur);
      const siteParsed = siteItem.type === 'ok'
        ? siteItem.data.Sites
        : '';
      return {
        ...acc,
        [siteParsed]: (acc[siteParsed] || 0) + 1,
      };
    }, {});
    return {
      errorMessage,
      totalPatients: patients.length,
      siteCount,
    };
  });
  return patientsWithError;
}


const FileListItem = ({
  onClear,
  currentData,
}: {
  onClear: () => void,
  currentData: FileUploadDataT,
}) => {
  const patientsWithError = fetchErrors(currentData, true);
  const [showModal, setShowModal] = useState<null | 'Errors' | 'Successes'>(null);
  return (
    <>
      {showModal && (
        <PreviewModal
          csvData={showModal === 'Errors' ? currentData.errors : currentData.successes}
          title={`${TITLE_MAPPING[currentData.dataType]} ${showModal}`}
          onClose={() => setShowModal(null)}
          showErrorReasonsSummary={showModal === 'Errors'}
        />
      )}
      <div className={cx('bg-white border mx-2 p-3 mb-4', {
        'border-danger': currentData.missingColumns != null,
      })}>
        <div className="d-flex align-items-center justify-content-between">
          <div className="w-100">
            {[{
              title: 'File Name',
              value: currentData.fileName,
            }, {
              title: 'Sheet Type',
              value: TITLE_MAPPING[currentData.dataType],
            }].map((item) => (
              <div className="mb-2">
                <div className="font-weight-bold text-smaller text-muted pr-2">{item.title}</div>
                <div className="text-break text-small pr-3">{item.value}</div>
              </div>
            ))}
          </div>
          <Button
            variant="link"
            className="text-small font-weight-bold text-decoration-none d-block mx-auto"
            onClick={() => {
              onClear();
            }}
          >
            ❌
          </Button>
        </div>
        {currentData.missingColumns && (
          <div className="text-center mt-3 text-danger text-small font-weight-bold">
            {`Failed to upload file. Please check the missing columns. ${currentData.missingColumns.join(', ')}`}
          </div>
        )}
        {currentData.missingColumns == null && (
          <div className="text-center mt-2 font-weight-bold d-flex align-items-center justify-content-between">
            <Button
              variant="primary"
              className="mt-3  text-white mx-2 w-100"
              onClick={() => setShowModal('Successes')}
            >
              <div className="text-small font-weight-bold">View Parsed</div>
              <div className="text-smaller">{`${currentData.successes.length} rows parsed.`}</div>
            </Button>
            <Button
              variant="danger"
              className="mt-3 d-block mx-2 w-100"
              onClick={() => setShowModal('Errors')}
            >
              <div className="text-small font-weight-bold">View Failures</div>
              <div className="text-smaller">{`${currentData.errors.length} rows failed to parse.`}</div>
            </Button>
          </div>
        )}
        {patientsWithError && (
          <div>
            {
              _.sortBy(
                patientsWithError,
                (e) => !e.errorMessage.startsWith(INVALID_ED_DISPOSITION)
              )
              .map(({ errorMessage, siteCount, totalPatients }) =>  {
                return (
                  <div key={errorMessage} className="text-left mt-3 text-small">
                    <div className="font-weight-bold">
                      {`${errorMessage} - `}
                      <span className="font-weight-normal">{`${totalPatients} pts`}</span>
                    </div>
                    {!errorMessage.startsWith(INVALID_ED_DISPOSITION) && (
                      <div className="text-muted">
                        {Object.keys(siteCount).map((site) => (
                          `${site}: ${siteCount[site]}`
                        )).join('\t')}
                      </div>
                    )}
                  </div>
                );
              })
            }
          </div>
        )}
      </div>
    </>
  );
};

export default FileListItem;
