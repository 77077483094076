import { NavigatorSchemaT } from "../navAssignment/navOptions";
import Select from 'react-select'
import { type MergedDataT } from "../mergedColumns/mergeData";
import { SITES_WITH_SITE_CODE } from '../siteData';
import './index.scss';
import { Form } from 'react-bootstrap';


const NavAssignment = ({
  setNavData,
  navData,
  index,
  patientsAssigned,
}: {
  navData: NavigatorSchemaT,
  index: number,
  patientsAssigned: MergedDataT[],
  setNavData: (navData: NavigatorSchemaT) => void,
}) => {
  return (
    <div
      className="bg-light mx-3 p-3 border my-3"
      style={{
        width: '15em',
      }}
    >
      <div className="text-small font-weight-bold pt-2 pb-3 text-center text-primary">{navData.navigatorName}</div>
      <Select
        defaultValue={navData.sites.map((site) => ({
          value: site,
          label: SITES_WITH_SITE_CODE.find(({ value }) => value === site)?.label || site,
        }))}
        isMulti
        className="text-small"
        options={SITES_WITH_SITE_CODE}
        onChange={(selected) => {
          const associatedSites = selected?.map((site) => site.value) || [];
          setNavData({
            ...navData,
            sites: associatedSites,
          });
        }}
        classNamePrefix="select"
      />
      <Form.Check
        type="switch"
        id="custom-switch"
        className="my-3 text-small"
        label="Spanish Priority?"
        checked={navData.prioritizeSpanish}
        onChange={(e) => {
          setNavData({
            ...navData,
            prioritizeSpanish: e.target.checked,
          });
        }}
      />
      <Form.Check
        type="switch"
        id="custom-switch"
        className="my-3 text-small"
        label="CHF Patients"
        checked={navData.chfPatients}
        onChange={(e) => {
          setNavData({
            ...navData,
            chfPatients: e.target.checked,
          });
        }}
      />
      <div className="mt-3 bg-white border text-small  p-3">
        <div className="d-flex justify-content-between align-items-start">
          <div className="pe-2">
            <span className="text-small mb-1">Referrals</span>
            <Form.Control
              type="number"
              className="text-small"
              value={navData.referralCount}
              onChange={(e) => {
                const newReferralCount = parseInt(e.target.value);
                setNavData({
                  ...navData,
                  referralCount: newReferralCount,
                });
              }}
            />
          </div>
          <div>
            <span className="text-small mb-1">Admin</span>
            <Form.Control
              type="number"
              className="text-small"
              value={navData.adminCount}
              onChange={(e) => {
                const newAdminCount = parseInt(e.target.value);
                setNavData({
                  ...navData,
                  adminCount: newAdminCount,
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="text-small d-flex flex-wrap justify-content-between">
        <div className="mt-2 w-50">
          <div className="text-muted font-weight-bold text-small"># Total </div>
          {patientsAssigned.length}
        </div>
        <div className="mt-2 w-50 ps-3">
          <div className="text-muted font-weight-bold text-small"># Referrals </div>
          {patientsAssigned.filter(({ data: { Identification } }) => Identification === 'Provider Referral').length}
        </div>
        <div className="mt-2 w-50">
        <div className="text-muted font-weight-bold text-small"># Spa. Referrals </div>
          {patientsAssigned.filter(({ data }) => data.Identification === 'Provider Referral' && data['Spanish Speaking'] === 'TRUE').length}
        </div>
        <div className="mt-2 w-50 ps-3">
          <div className="text-muted font-weight-bold text-small"># Admin </div>
          {patientsAssigned.filter(({ data: { Identification } }) => Identification === 'Administrator').length}
        </div>
        <div className="mt-2 w-50">
          <div className="text-muted font-weight-bold text-small"># Spa. Admin </div>
          {patientsAssigned.filter(({ data }) => data.Identification === 'Administrator' && data['Spanish Speaking'] === 'TRUE').length}
        </div>
      </div>
    </div>
  );
};

export default NavAssignment;
