import React, { type ReactNode } from 'react'
import { Button } from 'react-bootstrap';
import cx from 'classnames';
import './CardItem.scss';

const CardItem = ({
  children,
  className,
  title,
  saveOpts,
}: {
  children: ReactNode,
  className?: string,
  title: string,
  saveOpts?: null | {
    onSave: () => void,
    saveText: string,
  },
}) => {
  return (
    <div className={cx('CardItem border shadow-sm rounded', className)}>
      <div className="label-pill bg-primary text-white text-smaller font-weight-bold">{title}</div>
      <div className="p-3">
        {children}
      </div>
      {saveOpts && (
        <Button
          className="continue-btn mt-3 mx-auto d-block text-white w-100 font-weight-bold"
          onClick={saveOpts.onSave}
        >
          {saveOpts.saveText}
        </Button>
      )}
    </div>
  );
};

CardItem.defaultProps = {
  className: '',
  saveOpts: null,
};

export default CardItem;
