import moment from 'moment';
import { SymptoData } from '../types';
import { extractDemographics } from './meditechUtils';
import { DEFAULT_PROVIDER, SERVICE_INTRO_ENCOUNTER_TEXT, INVALID_ED_DISPOSITION, getLanguage } from './genericUtils';
import { generateNavReasonsForMicrostrategy } from './reasonsForNav';

export const formatCSVData = async (data: Record<string, string>): Promise<{
  type: 'error', error: string,
} | {
  type: 'ok', data: Omit<SymptoData, 'Intro Message' | 'Full Site Name' | 'Payor Category' | 'Secondary Navigator Name'>, warnings: Array<string>,
}> => {
  const warnings = [];
  const demographics = extractDemographics(data);
  if (demographics.type === 'error') {
    return { type: 'error', error: demographics.error };
  }
  const { data: demographicsData, warning: demographicsWarning } = demographics;
  warnings.push(...(demographicsWarning || []));

  if (data['Discharge_DTS'] == null || data['Discharge_DTS'].trim().length === 0) {
    return { type: 'error', error: 'Discharge DTS is empty' };
  }

  const VALID_DISPOSITIONS = [
    '07 LEFT AGAINST MEDICAL ADVICE',
    '07 LEFT WITHOUT TX COMPLETED',
    '07 LEFT WITHOUT BEING SEEN',
    '07 PT LEFT;AT RISK OF HARM',
    '01 TREATED AND DISCHARGED',
  ]
  if (data['ED_Disposition'] == null || data['ED_Disposition'].trim().length === 0) {
    return { type: 'error', error: 'ED Disposition is empty' };
  }

  if (!VALID_DISPOSITIONS.includes(data['ED_Disposition'].trim())) {
    return { type: 'error', error: `${INVALID_ED_DISPOSITION}: ${data['ED_Disposition']}` };
  }
  if (demographicsData['Phone Number'] == null || demographicsData['Phone Number'].trim().length === 0) {
    return { type: 'error', error: warnings.join(',') };
  }

  const parsedEDReferralDate = moment(data['ED_Arrival'], 'MM/DD/YYYY', true)
  if (!parsedEDReferralDate.isValid()) {
    warnings.push(`Invalid ED Arrival Date: ${data['ED_Arrival']}`);
  }
  const numEdVisits = String(data['Visits_6_Months'].trim().length > 0 ? Number(data['Visits_6_Months']) : 0);

  return {
    type: 'ok',
    data: {
      'ED Visit/Referral Date': moment(data['ED_Arrival'], 'MM/DD/YYYY').format('MM/DD/YYYY'),
      'Provider / On-Site Coordinator Name': DEFAULT_PROVIDER,
      'Patient\'s Primary Language': getLanguage((data['Language'] || '').trim(), demographicsData.MRN),
      'Spanish Speaking': getLanguage((data['Language'] || '').trim(), demographicsData.MRN) === 'Spanish' ? 'TRUE' : 'FALSE',
      'Reason for Navigation': generateNavReasonsForMicrostrategy({
        insuranceType: demographicsData['Payer Type'],
        numEDVisits6Months: Number(numEdVisits),
        site: demographicsData.Sites,
        chiefComplaint: demographicsData['Chief Complaint'],
        dischargeDiagnoses: demographicsData['Discharge Diagnosis'],
      }).join(','),
      'Priority Level': '',
      'Encounter Details/Comments': SERVICE_INTRO_ENCOUNTER_TEXT,
      'Navigator Name': '',
      'Visits within 6 mo\'s': numEdVisits,
      // dont send service intro
      Identification: 'Service Intro No Response',
      'No Service Introduction': 'FALSE',
      ...demographicsData,
    },
    warnings,
  }
};
