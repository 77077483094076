import { formatCSVData as formatMeditechMicrostrategy } from './meditechMicrostrategy';
import { formatCSVData as formatMeditechReferrals } from './meditechReferrals';
import { formatCSVData as formatEPICReferrals } from './epicReferrals';
import { formatCSVData as formatEPICMicrostrategy } from './epicMicrostrategy';

export const formatCSV = (
  data: Record<string, string>,
  type: 'EPIC-Microstrategy' | 'EPIC-Referrals' | 'Meditech-Microstrategy' | 'Meditech-Referrals'
) => {
  if (type === 'Meditech-Microstrategy') {
    return formatMeditechMicrostrategy(data);
  }
  if (type === 'Meditech-Referrals') {
    return formatMeditechReferrals(data);
  }
  if (type === 'EPIC-Referrals') {
    return formatEPICReferrals(data);
  }
  if (type === 'EPIC-Microstrategy') {
    return formatEPICMicrostrategy(data);
  }
  throw new Error(`Unknown type ${type}`);
}
