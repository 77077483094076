import React from 'react'
import Dropzone from 'react-dropzone'
import { Card } from 'react-bootstrap';
import { read, utils } from 'xlsx';
import csv from 'csvtojson';

const UploadPane =  ({
  onUpload,
  filesUploading,
  setFilesUploading,
}: {
  onUpload: (data: {
    records: null | Array<Record<string, string>>,
    fileName: string,
  }) => Promise<void>;
  filesUploading: number,
  setFilesUploading: (func: (num: number) => number) => void,
}) => {
  return (
    <>
      <Dropzone
        onDrop={(acceptedFiles) => {
          acceptedFiles.forEach(async (file) => {
            const fr = new FileReader();
            fr.onload = async () => {
              setFilesUploading((curLoading) => curLoading + 1);
              const fileName = file.name;
              const arrayBuffer = await file.arrayBuffer();

              const wb = read(arrayBuffer, {
                cellText:false,
                cellDates:true
              }); // parse the array buffer

              const frResult = await (async (): Promise<null | Array<Record<string, string>>> => {
                if (fr.result == null) {
                  return null;
                }
                if (fileName.endsWith('.csv')) {
                  return csv().fromString(String(fr.result))
                }
                if (fileName.endsWith('.xls') || fileName.endsWith('.xlsx')) {
                  return utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
                    rawNumbers: false,
                    raw: false,
                    defval: '',
                    dateNF: 'mm/dd/yyyy'
                  });
                }
                if (fileName.endsWith('.txt')) {
                  return csv({
                    delimiter: '|',
                  }).fromString(String(fr.result));
                }
                return null;
              })();

              await onUpload({
                records: frResult,
                fileName,
              });
              setFilesUploading((curLoading) => curLoading - 1);
            };
            fr.readAsText(file);
          })
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <Card {...getRootProps()} className="mx-auto p-3 text-center card">
            {(filesUploading > 0) && (
              <div className="display-4">
                Loading...
              </div>
            )}
            <input {...getInputProps()} />
            <div className="text-muted font-weight-light">
              Drag 'n Drop Raw CSV
            </div>
          </Card>
        )}
      </Dropzone>
    </>
  );
};

export default UploadPane;
